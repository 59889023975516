import { isNull, normalizeNumber, r } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../constant'
import type { ViewQueryMultiTablePivot, ViewQueryRecordNode, ViewQueryResult } from '../../schemas/query'
import { flatRecordNodes } from '../util/recordNodeUtil'
import { addInnerRowIndexMetadata } from './executeViewQuery/addMetadata'
import { generateLabelSqlFieldName } from './executeViewQuery/generateSql'
import { executeAggregationQuery, type SqlClient } from './executeViewQueryForAggregationQuery'

export type SqlResultRecord = Record<string, unknown>

interface UserOrUserGroup {
  id: string
  rank: number
}

export const executeViewQueryForMultiTablePivot = async ({
  query,
  sqlClient,
  mustacheParameter,
}: {
  query: ViewQueryMultiTablePivot
  sqlClient: SqlClient
  mustacheParameter: Record<string, unknown>
}): Promise<ViewQueryResult> => {
  // XXX: どういうロジックが理想か悩ましいが、ユーザー・ユーザーグループによるソートの実現のため、ここでusers,userGroupsをselectしている
  const users = await fetchUsers(query.pivot, sqlClient)
  const userGroups = await fetchUserGroups(query.pivot, sqlClient)
  const kpiIdToKpiGroupName = query.queries.toObject((q) => [
    q.kpiId,
    q.kpiGroupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME,
  ])

  // TODO: chunk
  const promises = query.queries.map(async (query) => {
    if (query.schema === null) {
      return { rows: [], sql: '', name: query.name }
    }
    const { rows, hasMoreRows, sql, error } = await executeAggregationQuery({
      query: query.schema,
      sqlClient,
      mustacheParameter,
      option: {
        logBody: {
          viewId: query.kpiId,
          viewName: query.kpiName,
        },
      },
    })
    // エラーのときの処理
    if (error !== undefined) {
      return {
        rows: [
          // ここで1レコード分は返さないと、カラムとして表示されない
          {
            [CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME]: query.kpiId,
            [generateLabelSqlFieldName(CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME)]: query.kpiName,
            [CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME]:
              query.kpiGroupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME,
            [CORE_CONSTANT.KPI_PIVOT_ROLE_COLUMN_NAME]: query.role,
          },
        ],
        sql,
        name: query.name,
        kpiName: query.kpiName,
        error: `${query.kpiName}のSQL実行中にエラーが発生しました。 ${error.message}`,
      } // TODO: エラー表示
    }

    // TODO: LIMIT
    const rowsWithKpiName = rows.map(
      (row): Record<string, unknown> => ({
        ...row,
        ...(query.role === 'goal'
          ? {
              // 目標のとき、KPIのIDはレコードに含まれるが、SQLが複雑になるのを避けてKPIグループ名はSQLに付与しないため、ここで処理する
              [CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME]:
                kpiIdToKpiGroupName[
                  (row[CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME] as string) ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME
                ],
            }
          : {
              [CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME]: query.kpiId,
              [generateLabelSqlFieldName(CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME)]: query.kpiName,
              [CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_NAME_COLUMN_NAME]:
                query.kpiGroupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME,
            }),
        [CORE_CONSTANT.KPI_PIVOT_ROLE_COLUMN_NAME]: query.role,
      }),
    )
    return { rows: rowsWithKpiName, hasMoreRows, sql, name: query.name, kpiName: query.kpiName }
  })
  const results = await Promise.all(promises)

  const allRows = results.flatMap((x) => x.rows)
  const reducedRows = allRows.slice(0, 50 * CORE_CONSTANT.AGGREGATION_QUERY_LIMIT) // グルーピング性能の悪化を防ぐため、複数の KPI のレコードを横断的に LIMITしておく
  const isRowReduced = allRows.length !== reducedRows.length || results.some((x) => x.hasMoreRows) //  AGGREGATION_ROWS_LIMIT 以上のレコードを持つ KPI が複数ある場合でも、バランスよくダッシュボードを表示するため KPI 毎にも LIMIT している
  const nodeRecords = groupByRowDimensions(
    allRows,
    query.pivot,
    query.sorter,
    query.options,
    1, // rootのテーブルノードが0なので、1始まりで正しい
    {
      users,
      userGroups,
    },
  )
  const sorted = sortByAggregatedValues(nodeRecords, query)
  const withInnerIndex = sorted.map((record) => addInnerRowIndexMetadata(record))
  const reducedResult = reduceRecordNode(withInnerIndex, CORE_CONSTANT.AGGREGATION_ROWS_LIMIT) // TODO: pagination
  return {
    viewQuery: query,
    result: reducedResult.records,
    sqls: results.map((x) => x.sql).compact(), // deprecated
    sqlDetails: results.map((x) => ({
      sql: x.sql,
      name: x.kpiName ?? x.name,
    })),
    nextCursor: undefined,
    error: results.isPresent()
      ? results
          .map((x) => x.error)
          .compact()
          .join(', ')
      : undefined,
    warn: isRowReduced
      ? `レコードが多すぎるため、全てを表示できません。`
      : reducedResult.reduced
        ? `行が多すぎるため、全てを表示できません。「集計項目 - 行」を減らすと問題が解消する場合があります。`
        : undefined,
    ui: {
      pivotColumns: query.pivot.columns.map((column, index) => {
        // TODO: fixedValues
        const valuesWithLabel = allRows
          .map((row) => ({
            value: forceString(row[column.name]) ?? '', // TODO: nullの扱いどうすべき？
            label:
              row[column.name] === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING
                ? '小計'
                : row[column.name] === CORE_CONSTANT.KPI_PIVOT_NULL_STRING
                  ? '-'
                  : (forceString(row[generateLabelSqlFieldName(column.name)]) ?? undefined),
          }))
          .uniqueBy((row) => row.value) // TODO: sort
          .sortBy((row) => {
            if (row.label === '小計') {
              return [(query.sorter?.totalColumnOrder === 'left' ? -2 : 2) * 10_000, row.value]
            }
            if (row.label === '-') {
              return [(query.sorter?.totalColumnOrder === 'left' ? -1 : 1) * 10_000, row.value]
            }
            if (column.fixedValues?.isPresent() ?? false) {
              return [column.fixedValues!.indexOf(row.value), row.value]
            }
            if (column.sortedValues?.isPresent() ?? false) {
              return [column.sortedValues!.indexOf(row.value), row.value]
            }
            if (column.name === CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key) {
              return [users.findIndex((x) => x.id === row.value), row.value]
            }
            if (column.name.startsWith(`p_salescore_user_groups`)) {
              // TODO
              return [userGroups.findIndex((x) => x.id === row.value), row.value]
            }
            return [0, row.value]
          })

        return {
          columnFieldName: column.name,
          valuesWithLabel,
        }
      }),
    },
  }
}

// UIでソート列を指定した場合、集計値によるソート（＝recordNodesをflatした状態でのソート）
// XXX: LIMITがあるケースを考えると、本来はSQL側でのsortが必要になるが、
//      複数テーブルをまたがって特定のテーブルのカラム値でソートするのは非常に難しいため、いったんアプリケーションレイヤーでソートを行う。
function sortByAggregatedValues(
  recordNodes: ViewQueryRecordNode[],
  query: ViewQueryMultiTablePivot,
): ViewQueryRecordNode[] {
  const keys = query.sorter?.columnKeys
  if (keys === undefined || keys.isBlank()) {
    return recordNodes
  }

  // 一旦flatにする
  const flatten = recordNodes.flatMap((x) => flatRecordNodes(x, []))
  // 小計を外す
  const notTotalNodes = flatten.filter((x) =>
    r(x)
      .values()
      .every((value) => value !== CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING),
  )
  // sortする
  const sortedNotTotalNodes = notTotalNodes.sortBy((x) => {
    const values = keys.map((key) => {
      const value = normalizeNumber(x[key.key]) ?? 0 // XXX: デフォルトを0としている。この挙動をやめたいが、ほとんどのKPIにおいてデフォルトは0であるような挙動が期待されており、client側では0と表示しているので、0としてしまう
      // やや雑だが、以下でasc/descを対応する
      if (key.order === 'desc') {
        return value * -1
      }
      return value
    })
    return values
  })

  // KPIの合計セルはソート後に追加する
  const kpiTotalNodes = recordNodes
    .filter((x) => x.id === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING)
    .flatMap((x) => flatRecordNodes(x, []))

  const sortedNodes =
    query.sorter?.totalRowOrder === 'top'
      ? [...kpiTotalNodes, ...sortedNotTotalNodes]
      : [...sortedNotTotalNodes, ...kpiTotalNodes]

  return sortedNodes.map((attributes, index): ViewQueryRecordNode => {
    return {
      id: `${index}`,
      attributes,
      children: [],
      meta: {
        height: 1,
        innerRowIndexStart: 0,
        innerRowIndexEnd: 1,
      },
    }
  })
}

function forceString(x: unknown): string | null | undefined {
  if (isNull(x)) {
    return x
  }

  if (typeof x === 'string') {
    return x
  }
  if (typeof x === 'number') {
    return x.toString()
  }
  return JSON.stringify(x)
}

function groupByRowDimensions(
  sqlRows: SqlResultRecord[],
  pivot: ViewQueryMultiTablePivot['pivot'],
  sorter: ViewQueryMultiTablePivot['sorter'],
  options: ViewQueryMultiTablePivot['options'],
  depth: number,
  resources: {
    users: UserOrUserGroup[]
    userGroups: UserOrUserGroup[]
  },
): ViewQueryRecordNode[] {
  const pivotRow = pivot.rows.first()
  if (pivotRow === undefined) {
    // TODO
    return [
      {
        id: 'leaf', // なんでもいいはず
        attributes: columnsToAttributes(
          sqlRows,
          pivot.columns.map((x) => x.name),
        ),
        meta: {
          height: 1,
          innerRowIndexStart: 0, // 後で更新
          innerRowIndexEnd: 0, // 後で更新
        },
        children: [],
      },
    ]
  }

  // validation

  const sqlRowsGroups = sqlRows.groupBy((x) => {
    const dimension = x[pivotRow.name]
    if (typeof dimension === 'string') {
      return dimension
    }
    // TODO

    return ``
  })

  return sqlRowsGroups
    .toArray()
    .sortBy(([dimensionValue]) => {
      // 行軸によるソート
      // 列軸のソートはcolumnKeysで行う
      // 値でのソートはこの後にsortByAggregationValuesで行う）
      // TODO: sorterがあればここで定義
      if (dimensionValue === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
        return [(sorter?.totalRowOrder === 'top' ? -2 : 2) * 100_000, dimensionValue]
      }
      // sortedValuesがセットされている場合はNULLの場合も位置を固定せず入れ替えができるようにする
      if (!(pivotRow.sortedValues?.isPresent() ?? false) && dimensionValue === CORE_CONSTANT.KPI_PIVOT_NULL_STRING) {
        return [(sorter?.totalRowOrder === 'top' ? -1 : 1) * 100_000, dimensionValue]
      }
      if (pivotRow.fixedValues?.isPresent() ?? false) {
        return [pivotRow.fixedValues!.indexOf(dimensionValue), dimensionValue]
      }
      if (pivotRow.sortedValues?.isPresent() ?? false) {
        return [pivotRow.sortedValues!.indexOf(dimensionValue), dimensionValue]
      }
      if (pivotRow.name === CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key) {
        return [resources.users.findIndex((x) => x.id === dimensionValue), dimensionValue]
      }
      if (pivotRow.name.startsWith(`p_salescore_user_groups`)) {
        // TODO
        return [resources.userGroups.findIndex((x) => x.id === dimensionValue), dimensionValue]
      }
      return [0, dimensionValue]
    })
    .map(([dimensionValue, sqlRowsGroup], index): ViewQueryRecordNode | undefined => {
      if ((options?.skipTotal ?? false) && dimensionValue === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING) {
        return undefined
      }
      const nextPivotRows = pivot.rows.slice(1)
      const nextPivotRow = nextPivotRows.first()
      const children = [
        {
          nodeName: nextPivotRow === undefined ? `valuesNode` : `dimensionNode${depth}`,
          children: groupByRowDimensions(
            sqlRowsGroup,
            {
              ...pivot,
              rows: nextPivotRows,
            },
            sorter,
            options,
            depth + 1,
            resources,
          ),
        },
      ]
      return {
        id: dimensionValue, // uniqueになるはず
        attributes: {
          [createDimensionFieldName(depth)]: dimensionValue, // TODO
          [`${createDimensionFieldName(depth)}_label`]:
            dimensionValue === CORE_CONSTANT.KPI_PIVOT_TOTAL_STRING
              ? `小計`
              : sqlRowsGroup.first()?.[generateLabelSqlFieldName(pivotRow.name)],
        },
        meta: {
          height: children.map((x) => x.children.map((y) => y.meta.height).sum()).max() ?? 1,
          innerRowIndexStart: 0, // 後で更新
          innerRowIndexEnd: 0, // 後で更新
        },
        children,
      }
    })
    .compact()
}

//
//
//
function columnsToAttributes(sqlRows: SqlResultRecord[], pivotColumns: string[]): ViewQueryRecordNode['attributes'] {
  return sqlRows
    .map((row) => {
      const role = row[CORE_CONSTANT.KPI_PIVOT_ROLE_COLUMN_NAME] as string // TODO
      const attributeName = JSON.stringify([...pivotColumns.map((column) => row[column] ?? null), role])
      const value = row[CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME] // TODO
      return [attributeName, value] as [string, number] // TODO
    })
    .toObject(([key, value]) => [key, value])
}

export function createDimensionFieldName(depth: number) {
  // depthは1始まりとする
  return `${CORE_CONSTANT.DIMENSION_FIELD_NAME_PREFIX}${depth}` // XXX: このフォーマットに一部ロジックが依存している
}

async function fetchUsers(pivot: ViewQueryMultiTablePivot['pivot'], sqlClient: SqlClient) {
  const dimensions = [...pivot.rows, ...pivot.columns]
  const userDimension = dimensions.find((x) => x.name === CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key)
  if (userDimension === undefined) {
    return []
  }
  const users = await sqlClient.query(`SELECT id, name, rank FROM salescore_users ORDER BY rank`)
  return users.rows as unknown as UserOrUserGroup[]
}

async function fetchUserGroups(pivot: ViewQueryMultiTablePivot['pivot'], sqlClient: SqlClient) {
  const dimensions = [...pivot.rows, ...pivot.columns]
  const userGroupDimensions = dimensions.filter((x) => x.name.startsWith(`p_salescore_user_groups`)) // TODO
  if (userGroupDimensions.isBlank()) {
    return []
  }
  const users = await sqlClient.query(`SELECT id, name, rank FROM salescore_user_groups ORDER BY rank`)
  return users.rows as unknown as UserOrUserGroup[]
}

//
// ここまでのロジックだと子レコード数(? 正確にはflatNodeしたときのレコード数)が制限されていない。
// バックエンドのロジックとしては問題がないが、フロントエンドでレンダリングする際に問題になる？ような挙動があったため、
// いったん表示行数を絞る対応をしておく
//
function reduceRecordNode(
  records: ViewQueryRecordNode[],
  limit: number,
): {
  records: ViewQueryRecordNode[]
  reduced: boolean
} {
  const sumHeight = records.map((x) => x.meta.height).sum()
  if (sumHeight <= limit) {
    return {
      records,
      reduced: false,
    }
  }

  let current = 0
  const result: ViewQueryRecordNode[] = []
  for (const record of records) {
    if (record.meta.height + current < limit) {
      result.push(record)
      current += record.meta.height
      continue
    }
    // heightが超えてしまう場合、超えないようなchildrenを求める
    const tableNode = record.children.first() // ピボット集計のコンテキストでは、childrenは1つしかないはず
    if (tableNode === undefined) {
      return {
        reduced: true,
        records: result,
      }
    }
    const reducedChildren = reduceRecordNode(tableNode.children ?? [], limit - current)
    result.push({
      ...record,
      children: [
        {
          ...tableNode,
          children: reducedChildren.records,
        },
      ],
    })
    return {
      reduced: true,
      records: result,
    }
  }

  // ここに辿り着くことはないはず
  return {
    reduced: true,
    records: result,
  }
}
