import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, message } from 'antd'
import { t } from 'i18next'
import type { CSSProperties } from 'react'

import { SortableListWrapper } from '../../form/SortableSelect/SortableListWrapper'
import { ButtonWithTooltip } from '../button/ButtonWithTooltip'
import { MyDropdown } from './MyDropdown'

interface Option {
  label: string
  value: string
  path?: string
}

const NotionLikeSelectorDropdownMenu = ({
  value,
  options,
  additionText,
  showAddition,
  disableAddition,
  uneditable,
  undeletable,
  setValue,
  onAddButtonClick,
  onEdit,
  onDestroy,
  onSort,
}: {
  value: string | undefined
  options: Option[]
  additionText?: string
  showAddition?: boolean
  disableAddition?: boolean
  uneditable?: (name: string) => boolean
  undeletable?: string[]
  setValue: (x: string) => void
  onAddButtonClick: () => void
  onEdit?: (value: string) => Promise<void> | void
  onDestroy?: (value: string) => void
  onSort?: (values: string[]) => void
}) => {
  return (
    <>
      <SortableListWrapper
        className="px-3 py-2"
        items={options.map((x) => ({
          ...x,
          key: x.value,
        }))}
        onChange={(items) => {
          // deleted
          const deletedItems = options.filter((x) => !items.map((item) => item.value).includes(x.value))
          if (deletedItems.length > 0) {
            // 削除は1つしかないはず
            if (onDestroy !== undefined) {
              onDestroy(deletedItems[0]!.value)
            }
          } else {
            // sortedのはず
            const sortedValues = items.map((x) => x.value)
            const originalValues = options.map((x) => x.value)
            if (sortedValues.join(',') !== originalValues.join(',')) {
              // TODO: onSortがないとき、ドラッグ動作もできなくしたいが未実装。いったん、雑に警告メッセージだけ出す
              if (onSort === undefined) {
                void message.warning(t(`権限がないため並び替えできません`))
              } else {
                onSort(sortedValues)
              }
            }
          }
        }}
        // className="mt-4"
        options={{
          bordered: false,
          uneditable,
          deletable: onDestroy !== undefined,
          undeletable,
          onClick: (item) => {
            setValue(item.value)
          },
          onEdit:
            onEdit === undefined
              ? undefined
              : async (item) => {
                  await onEdit(item.value)
                },
        }}
        value={value}
      />

      {showAddition === true && (
        <>
          <Divider className="my-1" />
          <ButtonWithTooltip
            disabled={disableAddition}
            showTooltip={disableAddition ?? false}
            tooltipTitle={t(`権限がありません`)}
            onClick={() => {
              onAddButtonClick()
            }}
            type="text"
            icon={<PlusOutlined />}
            block
          >
            {additionText ?? t(`新規作成`)}
          </ButtonWithTooltip>
        </>
      )}
    </>
  )
}

export const NotionLikeSelector = ({
  disabled,
  options,
  value,
  placeholder,
  buttonProps,
  additionText,
  className,
  dropdownStyle,
  showAddition,
  disableAddition,
  uneditable,
  undeletable,
  setValue,
  onAddButtonClick,
  onDestroy,
  onEdit,
  onSort,
}: {
  value: string | undefined
  setValue: (x: string) => void
  options: Option[]
  placeholder?: string
  buttonProps?: Parameters<typeof Button>[0]
  additionText?: string
  className?: string
  dropdownStyle?: CSSProperties
  showAddition?: boolean
  disableAddition?: boolean
  disabled?: boolean
  uneditable?: (name: string) => boolean
  undeletable?: string[]
  onAddButtonClick: () => void
  onDestroy?: (value: string) => void
  onEdit?: (value: string) => Promise<void> | void
  onSort?: (values: string[]) => void
}) => {
  const currentOption = options.find((x) => x.value === value)
  if (options.length === 0) {
    if (disabled === true) {
      return (
        <Button disabled icon={<PlusOutlined />}>
          {additionText ?? t(`新規作成`)}
        </Button>
      )
    }
    return (
      <div className={className}>
        {showAddition === true && (
          <ButtonWithTooltip
            disabled={disableAddition}
            showTooltip={disableAddition}
            tooltipTitle={t(`権限がありません`)}
            icon={<PlusOutlined />}
            onClick={onAddButtonClick}
          >
            {additionText ?? t(`新規作成`)}
          </ButtonWithTooltip>
        )}
      </div>
    )
  }

  if (disabled === true) {
    return (
      <Button disabled>
        {' '}
        {currentOption?.label ?? placeholder ?? t(`選択してください`)} <DownOutlined />{' '}
      </Button>
    )
  }

  return (
    <>
      <MyDropdown
        className={className}
        style={{
          width: 430,
          ...dropdownStyle,
        }}
        overlay={({ hide }) => {
          return (
            <NotionLikeSelectorDropdownMenu
              disableAddition={disableAddition}
              options={options}
              uneditable={uneditable}
              undeletable={undeletable}
              additionText={additionText}
              showAddition={showAddition}
              onAddButtonClick={onAddButtonClick}
              setValue={(value) => {
                setValue(value)
                hide()
              }}
              value={value}
              onDestroy={onDestroy}
              onEdit={onEdit}
              onSort={onSort}
            />
          )
        }}
      >
        <Button {...buttonProps}>
          {' '}
          {currentOption?.label ?? placeholder ?? t(`選択してください`)} <DownOutlined />{' '}
        </Button>
      </MyDropdown>
    </>
  )
}
