import type {
  ViewConfigField,
  ViewConfigForm,
  ViewConfigSheet,
  ViewConfigTreeNode,
  ViewConfigUiComponent,
} from '../../schemas/view_config'

export function convertSheetConfigToFormConfig(config: ViewConfigSheet): ViewConfigForm | undefined {
  if (config.tree === undefined) {
    return undefined
  }

  return {
    type: 'form',
    tree: config.tree,
    components: convertFieldToComponentsRec(config.tree, config.fields ?? []),
    filterTree: config.filterTree, // TODO: 本来はここでidのフィルタを指定したいが、idというプロパティがないため、指定しづらい
  }
}

function convertFieldToComponentsRec(node: ViewConfigTreeNode, fields: ViewConfigField[]): ViewConfigUiComponent[] {
  const targetFields = fields
    .filter((x) => x.property.nodeName === node.name)
    .filter((x) => x.property.propertyName !== '_url') // 決め打ちするのはやめたいが、一旦雑に作る
    .filter((x) => x.override?.uiColumn?.visible ?? true) // シートで表示している列だけを対象にする

  // このノードのattributesであるフィールドは、Colで並べる
  const components = targetFields.map((field): ViewConfigUiComponent => {
    return {
      componentType: `Col`,
      children: [
        {
          property: field.property,
          override: {
            queryField: field.override?.queryField,
          },
        },
      ],
    }
  })
  // 子要素は、Rowで区切って再起的にCol
  const childComponents = (node.children ?? []).map((child): ViewConfigUiComponent => {
    return {
      componentType: `ChildRecordsForm`,
      children: convertFieldToComponentsRec(child, fields),
      nodeName: child.name,
    }
  })
  return [
    {
      componentType: `Row`,
      children: components,
    },
    ...childComponents,
  ]
}
