import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

if (process.env.BUGSNAG_API_KEY !== undefined) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    releaseStage: process.env.APP_ENV,
    plugins: [new BugsnagPluginReact()],
  })
}

export const notifyBugsnag = ({
  error,
  user,
  severity,
}: {
  error: Error
  user?: {
    id: string
    email: string
    name: string
  }
  severity?: 'error' | 'warning' | 'info'
}) => {
  if (process.env.BUGSNAG_API_KEY === undefined) {
    return
  }

  Bugsnag.notify(error, function (event) {
    event.severity = severity ?? 'error'
    if (user) {
      event.setUser(user.id, user.email, user.name)
    }
  })
}
