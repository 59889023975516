import type { CompileResources } from '../../../functions/view/compileViewConfig'
import { allModels } from '../models'

export const compileResourceWithoutViews: CompileResources = {
  view: {
    id: ``,
    name: ``,
  },
  models: allModels,
  views: [],
  organizationSetting: {
    userGroupCategoryNames: [],
    dimensionGroups: [],
    accountClosingMonth: 0,
  },
}
