import { z } from 'zod'

import { type CoreIcon, iconSchema } from '../icon'
import { type ViewUIComponent, viewUISchema } from './component'
import { dataEntryCommonSchema, dataEntrySchema } from './dataEntry'
import { viewUiComponentTypeSchema } from './enum'

//
// form
//
// もはやラベル表示、エラー表示ロジックの共通化のためにしか使っていない
export const formItemSchema = dataEntryCommonSchema.merge(
  z.object({
    type: z.literal(viewUiComponentTypeSchema.enum.FormItem),
    label: z.string(),
    child: dataEntrySchema,
    // validationRules: validationRuleSchema.array().optional(), // TODO
  }),
)
export type ViewUIFormItem = z.infer<typeof formItemSchema>

// 不要そう
export const titleFormItemSchema = z.object({
  type: z.literal(viewUiComponentTypeSchema.enum.TitleFormItem),
  urlPath: z.string().array().optional(),
  valuePath: z.string().array().optional(),
  defaultTitle: z.string().optional(),
})
export type ViewUITitleFormItem = z.infer<typeof titleFormItemSchema>

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewUIForm = {
  type: 'Form'
  label: string
  children: ViewUIComponent[]
  nameFieldName?: string
  recordUrlFieldName?: string
  icon?: CoreIcon
  color?: string
}

export const formSchema: z.ZodSchema<ViewUIForm> = z.lazy(() =>
  z.object({
    type: z.literal('Form'),
    label: z.string(),
    children: viewUISchema,
    nameFieldName: z.string().optional(),
    recordUrlFieldName: z.string().optional(),
    icon: iconSchema.optional(),
    color: z.string().optional(),
  }),
)

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewUIChildRecordsForm = {
  type: 'ChildRecordsForm'
  label: string
  tableNodeName: string
  children: ViewUIComponent[]
  nameFieldName?: string
  recordUrlFieldName?: string
  relation?: 'many_to_one' | 'one_to_many' | 'one_to_one'
  icon?: CoreIcon
  color?: string
}

export const childRecordsFormSchema: z.ZodSchema<ViewUIChildRecordsForm> = z.lazy(() =>
  z.object({
    type: z.literal('ChildRecordsForm'),
    label: z.string(),
    tableNodeName: z.string(),
    children: viewUISchema,
    nameFieldName: z.string().optional(),
    recordUrlFieldName: z.string().optional(),
    relation: z.enum([`many_to_one`, `one_to_many`, 'one_to_one']).optional(),
    icon: iconSchema.optional(),
    color: z.string().optional(),
  }),
)

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewUIFormList = {
  type: 'FormList'
  label: string
  path: string[]
  children: ViewUIComponent[]
}

export const formListSchema: z.ZodSchema<ViewUIFormList> = z.lazy(() =>
  z.object({
    type: z.literal('FormList'),
    label: z.string(),
    path: z.string().array(),
    children: viewUISchema,
  }),
)
