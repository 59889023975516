import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import type { CoreView } from '../../../schemas/View'
import type { ViewConfig } from '../../../schemas/view_config'
import { getCompanySheetView } from './companySheetView'
import { getOpportunitySheetView } from './opporunitySheetView'
import { getUserSheetView } from './userSheetView'
import { compileResourceWithoutViews } from './util'

export function getShoudanKpiView(): CoreView {
  const opportunitySheetView = getOpportunitySheetView()
  const companySheetView = getCompanySheetView()
  const sheetViews = [companySheetView, getUserSheetView(), opportunitySheetView]
  const id = `商談数のviewId`
  const config: ViewConfig = {
    type: 'kpi',
    sheet: {
      type: `reference`,
      viewId: opportunitySheetView.id,
    },
    measure: {
      type: `kpiCustom`,
      sql: `count(*)`,
      dependentNodeNames: [],
    },
    ui: {
      kpi: {
        aggregationFunction: 'sum',
      },
    },
  }
  const result = compileViewConfig(config, { ...compileResourceWithoutViews, views: sheetViews })

  return {
    id,
    name: id,
    config,
    queries: result!.queries,
    ui: result!.ui,
  }
}
