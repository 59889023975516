import { z } from 'zod'

import { dataEntrySchema } from './dataEntry'
import {
  childRecordsFormSchema,
  formItemSchema,
  formListSchema,
  formSchema,
  titleFormItemSchema,
  type ViewUIChildRecordsForm,
  type ViewUIForm,
  type ViewUIFormList,
} from './form'
import { colSchema, rowSchema, type ViewUICol, type ViewUIRow } from './grid'
import { kpiSchema } from './kpi'
import { kpiPivotSchema } from './kpiPivot'
import { kpiTimeSeriesSchema } from './kpiTimeSeries'
import { SheetSchema } from './sheet'

//
// root
//
export type ViewUIComponent =
  | ViewUIRow
  | ViewUICol
  | ViewUIForm
  | ViewUIFormList
  | ViewUIChildRecordsForm
  | z.infer<typeof formItemSchema>
  | z.infer<typeof titleFormItemSchema>
  | z.infer<typeof SheetSchema>
  | z.infer<typeof kpiSchema>
  | z.infer<typeof kpiPivotSchema>
  | z.infer<typeof dataEntrySchema>
  | z.infer<typeof kpiTimeSeriesSchema>
// export type ViewUIComponent = z.infer<typeof viewUiComponentSchema>

export const viewUiComponentSchema = z.lazy(() =>
  z.union([
    rowSchema,
    colSchema,
    formItemSchema,
    titleFormItemSchema,
    formListSchema,
    childRecordsFormSchema,
    formSchema,
    SheetSchema,
    kpiSchema,
    kpiPivotSchema,
    dataEntrySchema,
    kpiTimeSeriesSchema,
  ]),
)

export const viewUISchema = viewUiComponentSchema.array()
export type ViewUI = z.infer<typeof viewUISchema>
