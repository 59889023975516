import type { CoreModel } from '../../../../../schemas/model/model'
import type { ViewQueryNode } from '../../../../../schemas/query'
import type { CompileContext } from '../../../compileViewConfig'
import { generateSearchSqlForSalesforceContact } from './generateSearchSqlForSalesforceContact'
import { generateSearchSqlForSalesforceRecordType } from './generateSearchSqlForSalesforceRecordType'
import { generateSearchSqlForSalesforceUserWithActive } from './generateSearchSqlForSalesforceUserWithActive'
import { generateSearchSqlForZohoLayout } from './generateSearchSqlForZohoLayout'
import { generateSearchSqlForZohoUserWithActive } from './generateSearchSqlForZohoUserWithActive'

function doubleQuote(x: string) {
  return `"${x}"`
}

export const SEARCH_RESULT_VALUE_COLUMN_NAME = 'value'
export const SEARCH_RESULT_LABEL_COLUMN_NAME = 'label'
export const SEARCH_RESULT_META_LABEL_COLUMN_NAME = 'meta_label' // camelCaseを使うと、quoteし忘れた際に小文字になってしまい不具合となるため、snake_caseとする
export interface SearchResultRow {
  value: string
  label: string
  meta_label?: string
}

export interface GenerateSearchSqlForStreamArgument {
  model: CoreModel
  parentModel: CoreModel
  parentNode: ViewQueryNode
  context: CompileContext
}

export function generateSearchSql(argument: GenerateSearchSqlForStreamArgument) {
  const { model } = argument

  // TODO: 結局,model側にもsourceの情報が必要?
  switch (model.name) {
    case 'zoho_layout': {
      return generateSearchSqlForZohoLayout(argument) ?? generateSearchSqlDefault(argument)
    }
    case 'salesforce_user': {
      return generateSearchSqlForSalesforceUserWithActive(argument) ?? generateSearchSqlDefault(argument)
    }
    case 'zoho_user': {
      return generateSearchSqlForZohoUserWithActive(argument) ?? generateSearchSqlDefault(argument)
    }
    case 'salesforce_contact': {
      return generateSearchSqlForSalesforceContact(argument) ?? generateSearchSqlDefault(argument)
    }
    case 'salesforce_record_type': {
      return generateSearchSqlForSalesforceRecordType(argument) ?? generateSearchSqlDefault(argument)
    }
  }
  return generateSearchSqlDefault(argument)
}

function generateSearchSqlDefault({ model }: GenerateSearchSqlForStreamArgument) {
  const nameProperty = model.properties.find((x) => x.meta === 'name')
  const labelField = nameProperty?.name ?? 'id'

  const searchSql = `SELECT
id as ${SEARCH_RESULT_VALUE_COLUMN_NAME},
"${labelField}" as ${SEARCH_RESULT_LABEL_COLUMN_NAME}
FROM "${model.name}"
WHERE ${generateSearchSqlMustache(doubleQuote(labelField))}`

  return {
    sql: orderByLength(searchSql, doubleQuote(labelField)),
    dependedPropertyNames: [
      nameProperty === undefined
        ? undefined
        : {
            streamName: model.name,
            propertyName: nameProperty.name,
          }, // idカラムを指定すべきか？
    ].compact(),
  }
}

export function generateSearchSqlMustache(fieldSql: string) {
  // v0
  // const OLD_SEARCH_SQL_KEY_PLACEHOLDER = `{{ searchKey }}` // v0
  // return `${fieldSql} LIKE '%${OLD_SEARCH_SQL_KEY_PLACEHOLDER}%'`
  // v1
  // return `{{ '${fieldSql}' | search }}`
  return `{{#queries}}
${fieldSql} ILIKE '%{{.}}%' AND
{{/queries}}
TRUE`
}

export function orderByLength(sql: string, labelField: string) {
  return `${sql}
  ORDER BY LENGTH(${labelField})`
}
