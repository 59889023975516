import { r } from '@salescore/buff-common'
import { v4 as uuidv4 } from 'uuid'

import { CORE_CONSTANT } from '../../constant'
import type { EltChange, EltChangesChunk } from './generateChangesChunk'

export type EltChangeResult =
  | {
      status: 'success'
      change: EltChange
      result: {
        id: string // changeがcreateのとき、生成されたid。それ以外のときは元のIDと同じであり、無意味な値
      }
    }
  | {
      status: 'unknown' | 'error'
      change: EltChange
      error: string
    }
export interface EltChangeResultsChunk {
  modelName: string
  results: EltChangeResult[]
}
// writeは一旦chunkごとに行う予定だが、後々の拡張性も想定して配列を渡すようにしておく
type WriteEltChangesFunction = (chunks: EltChangesChunk[]) => Promise<EltChangeResultsChunk[]>
type WriteEltChangesLogFunction = (transactionId: string, results: EltChangeResultsChunk[]) => Promise<void>

//
// ELTのソースに対して書き込みを行う
// このレイヤーでtry-catchしているので、この関数が例外を返すことはないはず
//
export async function writeChangesChunks(
  chunks: EltChangesChunk[],
  write: WriteEltChangesFunction,
  log: WriteEltChangesLogFunction,
) {
  const results: EltChangeResultsChunk[] = []
  const transactionId = uuidv4()

  // TODO: パフォーマンスの最適化を行いたければ、並列実行して良いchunkは並列実行したい。
  for (const chunk of chunks) {
    // ここまでの書き込み結果をもとに、必要があれば値を編集する
    const convertedChunk = convertTemporaryIdValue(chunk, results)

    // 順番に書き込む
    // writeChangesChunkはtry-catchしているため、失敗はあり得ない
    const result = await writeChangesChunk(convertedChunk.valid, write)
    results.push(...result)
    if (convertedChunk.invalid !== undefined && convertedChunk.invalid.changes.length > 0) {
      results.push({
        modelName: convertedChunk.invalid.modelName,
        results: convertedChunk.invalid.changes.map((change) => ({
          status: 'error',
          change,
          error: `親レコードの作成に失敗したため、保存されませんでした`,
        })),
      })
    }
  }

  // 書き込み結果をログに残す
  // 基本的にエラーは発生しない想定だが、万が一ネットワークエラーなどが起きた際に備える。
  // ユーザー影響は薄いので、ユーザーには伝えず、try-catchしてしまう。（TODO: この方針でいいだろうか？）
  try {
    await log(transactionId, results)
    return {
      status: 'success' as const,
      results,
    }
  } catch (error) {
    return {
      status: 'loggingError' as const,
      error,
      results,
    }
  }
}

async function writeChangesChunk(
  chunk: EltChangesChunk,
  write: WriteEltChangesFunction,
): Promise<EltChangeResultsChunk[]> {
  try {
    // 個々のレコードのエラー通知、ロギングなどをどうするか？
    const results = await write([chunk])
    return results
  } catch (error) {
    // write中のエラーは、write側で対処する＝ここでは例外が発生しないことを期待するが、実装のミスで例外になった場合はここで対応
    // TODO: 素のエラーを見せていいのか？
    const errorMessage = error instanceof Error ? `${error.name}: ${error.message}` : `エラーが発生しました`
    return [
      {
        modelName: chunk.modelName,
        results: chunk.changes.map((change): EltChangeResult => {
          // data, beforeは不要なので消す

          return {
            status: `unknown` as const,
            change,
            error: errorMessage,
          }
        }),
      },
    ]
  }
}

function convertTemporaryIdValue(
  chunk: EltChangesChunk,
  results: EltChangeResultsChunk[],
): {
  valid: EltChangesChunk
  invalid?: EltChangesChunk
} {
  const mapperPerModelName = results.map((result) => {
    return {
      modelName: result.modelName,
      mapper: result.results
        .map((x) => {
          if (x.status !== 'success') {
            return
          }
          return {
            temporaryId: x.change.id,
            id: x.result.id,
          }
        })
        .compact(),
    }
  })
  // TODO: 現状、参照の情報がないのでmodelNameごとにマッピングすることができず、結局一緒にしている
  const mappers = mapperPerModelName.flatMap((x) => x.mapper)
  // ※新規作成したものが1つもなくとも、valid/invalidのロジックで以下が必要

  // TODO: 参照しているかどうかをチェックせず全ての値をとにかく変換対象としているので、効率がかなり悪い。
  //       一度に更新するのはせいぜい1000レコード以下と考えられるのでパフォーマンス的に問題ない想定だが、問題になれば改修
  const mapper = mappers.groupBy((x) => x.temporaryId).transformValues((vs) => vs.first()!.id).data
  const convertedChanges = chunk.changes.map((change): EltChange => {
    if (change.type === 'delete') {
      return change
    }
    return {
      ...change,
      data: r(change.data).transformValues((v) => {
        if (typeof v !== 'string') {
          return v
        }
        // デバッグ用にやや冗長に書いている
        if (mapper[v] !== undefined) {
          return mapper[v]
        }
        return mapper[v] ?? v
      }).data,
    }
  })
  // validとinvalidに分ける
  const [valid, invalid] = convertedChanges.partition(
    (x) =>
      x.type === 'delete' ||
      r(x.data)
        .values()
        .every((v) => typeof v !== 'string' || !v.startsWith(CORE_CONSTANT.VIEW_NEW_RECORD_PREFIX)),
  )
  return {
    valid: {
      modelName: chunk.modelName,
      changes: valid,
    },
    invalid: {
      modelName: chunk.modelName,
      changes: invalid,
    },
  }
}
