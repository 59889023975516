import type { PgEmulator } from '@salescore/buff-common'

import { companyModel, initializeCompanyModelTable } from './companyModel'
import { contactModel, initializecontactModelTable } from './contactModel'
import { initializeOpportunityModelTable, opportunityModel } from './opportunityModel'
import { opportunitySnapshotModel, opportunitySnapshotSequenceModel } from './opportunitySnapshotModel'
import { initializeSalescoreUserModelTable, salescoreUserModel, salescoreUserRecords } from './salescoreUserModel'
import { initializeUserModelTable, userModel } from './userModel'

export const allModels = [
  companyModel,
  contactModel,
  opportunityModel,
  userModel,
  salescoreUserModel,
  opportunitySnapshotModel,
  opportunitySnapshotSequenceModel,
]

export const models = {
  allModels,
  companyModel,
  contactModel,
  opportunityModel,
  userModel,
  salescoreUserModel,
  salescoreUserRecords,
  initializeRecords(database: PgEmulator) {
    initializeUserModelTable(database)
    initializeCompanyModelTable(database)
    initializeOpportunityModelTable(database)
    initializeSalescoreUserModelTable(database)
    initializecontactModelTable(database)
    return database
  },
}
