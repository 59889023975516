import { z } from 'zod'

import { viewUiDimensionCandidateSchema } from './dimension'
import { viewUiComponentTypeSchema } from './enum'

// TODO: RI
export const kpiTimeSeriesSchema = z.object({
  type: z.literal(viewUiComponentTypeSchema.enum.KpiTimeSeriesGraph),
  dimensions: viewUiDimensionCandidateSchema.array(),
  queriedDimensions: z.union([z.literal('createdDate'), z.literal('breakdownProperty')]).array(),
  // 滝チャート用
  waterfall: z
    .object({
      dimensions: z
        .object({
          name: z.string(),
          label: z.string(),
        })
        .array(),
    })
    .optional(),
})
export type ViewUIKpiTimeSeries = z.infer<typeof kpiTimeSeriesSchema>
