import { App, Layout } from 'antd'
import type { AppProps } from 'next/app'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { AuthenticatedApolloProvider } from '../../providers/AuthenticatedApolloProvider'
import { I18nProvider } from '../../providers/I18nProvider'
import { GlobalLauncher } from '../launcher/GlobalLauncher'
import { Sider } from './Sider'

export const DefaultLayout = (properties: AppProps): JSX.Element => {
  return (
    <AuthenticatedApolloProvider>
      <App component={false}>
        <Content {...properties} />
      </App>
    </AuthenticatedApolloProvider>
  )
}

function Content(properties: AppProps): JSX.Element {
  const { Component, router } = properties

  return (
    <>
      <Layout style={{ height: '100%' }} hasSider>
        <Sider />
        <Layout.Content className="overflow-scroll-layout-content overflow-visible-print pb-16">
          <SwitchTransition>
            <CSSTransition key={router.asPath} timeout={200} classNames="page-transition">
              <I18nProvider>
                <Component />
              </I18nProvider>
            </CSSTransition>
          </SwitchTransition>
        </Layout.Content>
      </Layout>
      <GlobalLauncher />
    </>
  )
}
