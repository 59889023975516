import { isNull } from '@salescore/buff-common'
import { z } from 'zod'

import { CORE_CONSTANT } from '../../../../constant'
import type {
  ViewQueryDimensionFieldForMultiTablePivot,
  ViewQueryMultiTablePivot,
  ViewQueryMultiTablePivotQuery,
} from '../../../../schemas/query'
import type {
  ViewUiDimensionCandidate,
  ViewUiDimensionCandidateProperty,
  ViewUiKpiPivot,
} from '../../../../schemas/ui/ui'
import type {
  ViewConfigAdditionalConfig,
  ViewConfigDimension,
  ViewConfigKpi,
  ViewConfigKpiPivot,
  ViewConfigPivot,
} from '../../../../schemas/view_config'
import type { CompileContext, CompileViewConfigResponse } from '../../compileViewConfig'
import { compileKpiViewConfigWithoutGoal } from '../kpi/compileKpiViewConfig'
import { generateNodeFromDimension } from '../kpi/generateNodeFromDimension'
import { generateDimensionFieldName } from '../sheet/measure/generateDimensionField'
import { isMatchToDimensionGroup } from '../sheet/searchFieldAndPropertyForDimension'
import { compileGoalConfig } from './compileGoalConfig'

// TODO: KpiPivotSheetとしてcompileしてしまっているので、KpiSheetにしたい。
export function compileKpiPivotViewConfig(
  config: ViewConfigKpiPivot,
  context: CompileContext,
): CompileViewConfigResponse {
  const { logs, viewSearcher } = context
  const pivot: ViewConfigPivot | undefined =
    context.additionalConfig?.kpiParameter?.pivot ?? CORE_CONSTANT.KPI_PIVOT_DEFAULT_PRESET().parameter.pivot
  // SQL上でkpiは不要なので、pivotからkpiの軸を外したくなるが、その操作を行うと諸々の整合性が取りづらくなるのでここで除去せず、measure側でよしなに除去する
  // const pivotWithoutKpi = pivot !== undefined ? {
  //   rows: pivot.rows.filter(x => x.fieldName !== CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.fieldName),
  //   columns: pivot.columns.filter(x => x.fieldName !== CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.fieldName),
  // } : undefined
  const kpis = config.kpis
    .filter((kpiReference) => {
      // 本当はここのコンパイルは実行し、UIレイヤでKPIを非表示にしたいが、ピボットの列情報はqueryの実行時に動的に決まるため、ここで排除する以外に方法がない
      if (isInvisibleKpi(kpiReference.viewId, context.additionalConfig)) {
        return false
      }
      if (
        isFilteredKpiGroup(kpiReference.kpiGroupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME, context.additionalConfig)
      ) {
        return false
      }
      return true
    })
    .map((kpiReference) => {
      const view = viewSearcher.searchView(kpiReference.viewId)
      if (view === undefined) {
        // TODO: 削除側の実装がやや微妙でエラーになりやすいので、一旦エラーを表示しないようにした。
        // logs.error(
        //   `KPIビューが見つかりませんでした。viewId: ${kpiRef.viewId}. 現在のビュー: ${viewSearcher.views
        //     .map((x) => x.id)
        //     .join(',')}`,
        // )
        return
      }
      const kpi = view.config
      if (kpi.type !== 'kpi') {
        logs.error(`このビューはKPIではありません。viewId: ${kpiReference.viewId}, type: ${kpi.type ?? ''}`)
        return
      }
      return {
        kpiRef: kpiReference,
        view,
        kpi,
      }
    })
    .compact()

  const compiles = kpis
    .flatMap((kpi, index) => {
      const compiled = compileKpiViewConfigWithoutGoal(kpi.kpi, pivot, {
        ...context,
        resources: {
          ...context.resources,
          view: kpi.view,
        },
      })
      const query: ViewQueryMultiTablePivotQuery = {
        name: [kpi.view.id, `actual`].join('_'), // TODO: 結局使っていない
        kpiId: kpi.view.id,
        kpiName: kpi.view.name,
        kpiGroupName: kpi.kpiRef.kpiGroupName ?? CORE_CONSTANT.KPI_GROUP_DEFAULT_NAME,
        role: `actual`,
        schema: compiled?.query ?? null,
      }
      return {
        compiled,
        query,
      }
    })
    .compact()
  const sortKeys = context.additionalConfig?.kpiParameter?.sorter?.columnKeys ?? []
  const query: ViewQueryMultiTablePivot = {
    type: 'multiTablePivot',
    tree: sortKeys.isPresent()
      ? generateNodeFromDimension({ rows: [], columns: [] }, 0, [])
      : generateNodeFromDimension(pivot, 0, []),
    pivot: {
      rows: generateQueryPivotDimensions(
        pivot.rows,
        kpis.map((x) => x.view.id),
      ),
      columns: generateQueryPivotDimensions(
        pivot.columns,
        kpis.map((x) => x.view.id),
      ),
    },
    sorter: {
      ...context.additionalConfig?.kpiParameter?.sorter,
      totalRowOrder: context.additionalConfig?.kpiParameterPerUser?.totalRowOrder,
      totalColumnOrder: context.additionalConfig?.kpiParameterPerUser?.totalColumnOrder,
    },
    queries: [
      ...compiles.map((x) => x.query),
      ...compileGoalConfig({
        goalConfig: context.additionalConfig?.kpiParameter?.goalConfig,
        pivot,
        context,
        kpiIds: kpis.map((x) => x.view.id), // goalConfigに紐づくkpis = このコンテキストのkpis が常に成り立つだろうか？
      }),
    ].compact(),
    options: {
      skipTotal: context.additionalConfig?.kpiParameterPerUser?.skipTotal,
    },
  }

  const ui: ViewUiKpiPivot = {
    type: 'KpiPivotSheet',
    kpis: kpis.map((kpi) => ({
      id: kpi.view.id,
      label: kpi.view.name,
      withDateField: kpi.kpi.date !== undefined,
      groupName: kpi.kpiRef.kpiGroupName,
      ...kpi.kpi.ui?.kpi,
    })),
    dimensions: generateDimensionsCandidatesForMultiKpi(
      [...compiles.flatMap((x) => x.compiled?.ui.dimensions ?? []), CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION],
      kpis.map((x) => x.kpi),
      context,
    ),
    pivot: {
      // TODO: この愚直なマージで方法で、各種ケースを網羅できてるか不安
      rows: pivot.rows.map((row, index) => {
        const uiPivotRows = compiles.map((x) => x.compiled?.ui.pivot.rows[index]).compact()
        return {
          key: row.key,
          label:
            uiPivotRows
              .map((x) => x.label)
              .compact()
              .first() ?? row.label,
          fieldType:
            uiPivotRows
              .map((x) => x.fieldType)
              .compact()
              .first() ?? `string`,
          fieldMetaType: uiPivotRows
            .map((x) => x.fieldMetaType)
            .compact()
            .first(),
          selectOptions: uiPivotRows
            .map((x) => x.selectOptions)
            .compact()
            .first(),
        }
      }),
      columns: pivot.columns.map((column, index) => {
        const uiPivotColumns = compiles.map((x) => x.compiled?.ui.pivot.columns[index]).compact()
        return {
          key: column.key,
          label:
            uiPivotColumns
              .map((x) => x.label)
              .compact()
              .first() ?? column.label,
          fieldType:
            uiPivotColumns
              .map((x) => x.fieldType)
              .compact()
              .first() ?? `string`,
          fieldMetaType: uiPivotColumns
            .map((x) => x.fieldMetaType)
            .compact()
            .first(),
          selectOptions: uiPivotColumns
            .map((x) => x.selectOptions)
            .compact()
            .first(),
        }
      }),
    },
    appearance: config.ui?.appearance,
  }
  return {
    ui: [ui],
    query,
    queries: [query],
    context,
    // 自動インデックス付与のロジックで使う
    // 本来であればflatMapする
    extra: compiles.map((c) => c.compiled?.extra).compact(),
  }
}

function generateDimensionsCandidatesForMultiKpi(
  ds: ViewUiDimensionCandidate[],
  kpis: ViewConfigKpi[],
  context: CompileContext,
): ViewUiDimensionCandidate[] {
  // const fieldTypeDimensions: ViewUiDimensionCandidate[] = ds
  //   .map((x) => (x.type === 'fieldName' ? x : undefined))
  //   .compact()
  //   .groupBy((x) => x.key)
  //   .transformValues((dimensions): ViewUiDimensionCandidate => {
  //     const dimension = dimensions.first()!
  //     return {
  //       ...dimension,
  //       dimensionCount: dimensions.length,
  //     }
  //   })
  //   .values()

  const propertyTypeDimensions: ViewUiDimensionCandidateProperty[] = ds
    .map((x) => (x.type === 'property' ? x : undefined))
    .compact()
    .groupBy((x) => x.key)
    .transformValues((dimensions): ViewUiDimensionCandidateProperty => {
      const dimension = dimensions.first()!
      return {
        ...dimension,
        dimensionCount: dimensions.length,
      }
    })
    .values()

  const dimensionGroups = context.resources.organizationSetting.dimensionGroups
  const goalConfig = context.additionalConfig?.kpiParameter?.goalConfig
  const dimensionGroupTypeDimensions = dimensionGroups
    .map((group): ViewUiDimensionCandidate | undefined => {
      const matched = group.properties
        .map((dimensionGroupProperty) =>
          propertyTypeDimensions.find((x) =>
            isMatchToDimensionGroup(
              x.property,
              dimensionGroupProperty,
              context.additionalConfig?.kpiParameter?.goalConfig,
            ),
          ),
        )
        .compact()
      const matchedToGoal = [
        goalConfig?.goalDimension1?.id,
        goalConfig?.goalDimension2?.id,
        goalConfig?.goalDimension3?.id,
        goalConfig?.goalDimension4?.id,
        goalConfig?.goalDimension5?.id,
      ]
        .compact()
        .intersection(
          group.properties.map((x) => (x.type === 'goalDimension' ? x.goalDimensionId : undefined)).compact(),
        )
        .isPresent()
      const matches = matched.length + (matchedToGoal ? 1 : 0)
      // 初期仕様だと、一致数が2未満だと出す意味がないとみなして表示していなかったが、ユーザーにとって分かりづらい仕様なのでやめる
      // if (matches < 2) {
      //   return undefined
      // }
      return {
        type: 'dimensionGroup',
        key: [`g`, group.id].join('_'),
        label: group.label,
        groupId: group.id,
        fieldType: 'string', // TODO: どうしよう？
        dimensionCount: matched.map((x) => x.dimensionCount ?? 0).sum(),
      }
    })
    .compact()
  // const labelTypeDimensions = ds
  //   .uniqueBy((x) => x.label)
  //   .map(
  //     (x): ViewUiDimensionCandidate => ({
  //       ...x,
  //       type: 'fieldLabel',
  //       fieldName: x.label,
  //     }),
  //   )
  // const aliases = kpis.flatMap((x) => x.measures).flatMap((x) => x.measureWithDimension.dimensionAliases ?? [])
  // const aliasTypeDimensions = aliases
  //   .uniqueBy((x) => x.alias)
  //   .map(
  //     (x): ViewUiDimensionCandidate => ({
  //       type: 'alias',
  //       key: `a_${x.alias}`,
  //       alias: x.alias,
  //       fieldType: 'string',
  //       label: x.alias,
  //     }),
  //   )
  const dateKpis = kpis.map((x) => (x.date === undefined ? undefined : x)).compact()

  return [
    {
      ...CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION(),
      dimensionCount: kpis.length,
    },
    {
      ...CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION,
      dimensionCount: kpis.length,
    },
    {
      ...CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION(),
      dimensionCount: dateKpis.length,
    },
    // ...fieldTypeDimensions,
    ...propertyTypeDimensions,
    ...dimensionGroupTypeDimensions,
    // ...labelTypeDimensions,
    // ...aliasTypeDimensions,
  ]
    .filter((x) => x.dimensionCount === undefined || x.dimensionCount > 0)
    .uniqueBy((x) => x.key)
}

function generateQueryPivotDimensions(
  dimensions: ViewConfigDimension[],
  kpiIds: string[],
): ViewQueryDimensionFieldForMultiTablePivot[] {
  return dimensions.map((d): ViewQueryDimensionFieldForMultiTablePivot => {
    if (d.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key) {
      return {
        name: d.key,
        fixedValues: kpiIds,
      }
    }

    return {
      name: generateDimensionFieldName(d),
      sortedValues: d.sortedValues,
    }
  })
}

function isInvisibleKpi(kpiId: string, additionalConfig?: ViewConfigAdditionalConfig) {
  const invisibleKpiIds = additionalConfig?.kpiParameter?.invisibleKpiIds ?? []
  // logs.warn(`KPIは非表示です。viewId: ${kpiRef.viewId}`)
  return invisibleKpiIds.includes(kpiId)
}

function isFilteredKpiGroup(kpiGroupName: string, additionalConfig?: ViewConfigAdditionalConfig) {
  const dimensionFilterLeafs = additionalConfig?.kpiParameter?.dimensionFilterLeafs ?? []
  const kpiGroupDimensionFilterLeaf = dimensionFilterLeafs.find((x) => {
    return x.type === 'dimension' && x.dimension.key === CORE_CONSTANT.KPI_PIVOT_KPI_GROUP_DIMENSION().key
  })
  if (isNull(kpiGroupDimensionFilterLeaf)) {
    return false
  }
  const parsedFilterValue = z.string().array().safeParse(kpiGroupDimensionFilterLeaf.filterValue)
  if (!parsedFilterValue.success) {
    return false
  }
  return !parsedFilterValue.data.includes(kpiGroupName)
}
