import type { CoreView } from '../../../schemas/View'
import { getCompanyFormView } from './companyFormView'
import { getCompanySheetView } from './companySheetView'
import { getKpiPivotView } from './kpiPivotSheetView'
import { getOpportunityFormView } from './opportunityFormView'
import { getOpportunitySheetView } from './opporunitySheetView'
import { getShoudanKpiView } from './shoudanKpiView'
import { getShoudanSakuseiKpiView } from './shoudanSakuseiKpiView'

export const views = {
  getCompanyFormView,
  getCompanySheetView,
  getOpportunityFormView,
  getOpportunitySheetView,
  getShoudanKpiView,
  getShoudanSakuseiKpiView,
  getKpiPivotView,
  getViews(): CoreView[] {
    return [
      getCompanyFormView(),
      getCompanySheetView(),
      getOpportunityFormView(),
      getOpportunitySheetView(),
      getShoudanKpiView(),
      getShoudanSakuseiKpiView(),
      getKpiPivotView(),
    ]
  },
}
