import {
  BankOutlined,
  CloudServerOutlined,
  DashboardOutlined,
  FlagOutlined,
  LogoutOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useRedirect } from '../../hooks/useRedirect'
import { Logo } from '../shared/Logo'

const Divider = () => <hr style={{ backgroundColor: 'white', opacity: 0.3, margin: 0 }}></hr>

export const Sider = () => {
  const redirect = useRedirect()
  const router = useRouter()

  const items = [
    {
      label: '組織一覧',
      key: '/',
      icon: <BankOutlined />,
      onClick: () => {
        redirect('/')
      },
    },
    {
      label: 'スクリプト実行',
      key: '/tools',
      icon: <ToolOutlined />,
      onClick: () => {
        redirect('/tools')
      },
    },
    {
      label: 'キュー処理状況',
      key: '/workers',
      icon: <CloudServerOutlined />,
      onClick: () => {
        redirect('/workers')
      },
    },
    {
      label: 'ダッシュボード一覧',
      key: '/dashboards',
      icon: <DashboardOutlined />,
      onClick: () => {
        redirect('/dashboards')
      },
    },
    {
      label: '機能フラグ有効一覧',
      key: '/feature_flags',
      icon: <FlagOutlined />,
      onClick: () => {
        redirect('/feature_flags')
      },
    },
    {
      label: 'ログアウト',
      key: '/sign_out',
      icon: <LogoutOutlined />,
      onClick: () => {
        redirect('/sign_out')
      },
    },
  ]

  return (
    <Layout.Sider
      theme="light"
      className="dispaly-none-print fixed inset-y-0 left-0 h-screen overflow-auto  font-bold"
      // 以下、モバイル対応
      breakpoint="lg"
      collapsedWidth={0}
      zeroWidthTriggerStyle={{}}
      width={200}
    >
      <div className="px-4 py-5 text-center">
        <Link href="/" legacyBehavior>
          <Logo />
        </Link>
      </div>
      <Divider />
      <Menu
        defaultSelectedKeys={[router.pathname]}
        selectedKeys={[router.pathname]}
        mode="inline"
        theme="light"
        style={{
          paddingTop: 12,
          paddingBottom: 30,
        }}
        items={items}
      />
    </Layout.Sider>
  )
}
