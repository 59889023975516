import { i18nTranslations } from '@salescore/i18n'
import i18n from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'

void i18n.use(initReactI18next).init({
  resources: {
    // 自分の用意した翻訳ファイルを任意の場所から読み込む
    ja: {
      translation: i18nTranslations.ja,
    },
  },
  lng: 'ja',
  // debug: true,
  fallbackLng: 'ja', // デフォルトの言語を設定する
  interpolation: {
    escapeValue: false,
  },
})

export const I18nProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      {children}
    </I18nextProvider>
  )
}
