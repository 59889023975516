import { CORE_CONSTANT } from '../../../constant'
import type { CoreModel } from '../../../schemas/model/model'
import { opportunityModel } from './opportunityModel'

const OPPORTUNITY_SNAPSHOT_MODEL_NAME = `${CORE_CONSTANT.SNAPSHOT_MODEL_PREFIX}_${opportunityModel.name}`
const OPPORTUNITY_SNAPSHOT_SEQUENCE_MODEL_NAME = `${OPPORTUNITY_SNAPSHOT_MODEL_NAME}_${CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX}`

export const opportunitySnapshotModel: CoreModel = {
  ...opportunityModel,
  name: OPPORTUNITY_SNAPSHOT_MODEL_NAME,
  label: `${opportunityModel.label}スナップショット`,
  properties: [
    ...opportunityModel.properties,
    {
      name: 'id',
      type: 'string',
      label: 'id',
      referenceTo: [
        {
          modelName: opportunityModel.name,
        },
      ],
    },
    {
      meta: 'relation',
      name: CORE_CONSTANT.SNAPSHOT_SEQUENCE_ID,
      type: 'string',
      label: 'スナップショットシーケンスID',
      referenceTo: [
        {
          modelName: OPPORTUNITY_SNAPSHOT_SEQUENCE_MODEL_NAME,
        },
      ],
    },
  ],
}

export const opportunitySnapshotSequenceModel: CoreModel = {
  name: OPPORTUNITY_SNAPSHOT_SEQUENCE_MODEL_NAME,
  label: `${OPPORTUNITY_SNAPSHOT_SEQUENCE_MODEL_NAME}(sequence)`,
  properties: [
    {
      name: 'id',
      type: 'string',
      label: 'id',
    },
    {
      name: 'created_at',
      type: 'datetime',
      label: 'created_at',
    },
  ],
}
