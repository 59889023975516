const toOrdering = (x: number): Ordering => {
  if (x < 0) {
    return -1
  }
  if (x > 0) {
    return 1
  }
  return 0
}

const orderNullalbe = (a: unknown, b: unknown) => {
  const rankA = a === null ? 1 : a === undefined ? 2 : 0
  const rankB = b === null ? 1 : b === undefined ? 2 : 0
  return toOrdering(rankA - rankB)
}

export const compareFunction = (a: unknown, b: unknown): Ordering => {
  if (typeof a === 'number' && typeof b === 'number') {
    return toOrdering(a - b)
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return toOrdering(a.localeCompare(b))
  }
  if (a === undefined || b === undefined || a === null || b === null) {
    return orderNullalbe(a, b)
  }

  return toOrdering(JSON.stringify(a).localeCompare(JSON.stringify(b)))
}

export function isSome<T>(x: T): x is Exclude<T, undefined | null> {
  return x !== undefined && x !== null
}

export function isNull(x: unknown): x is undefined | null {
  return !isSome(x)
}

export function isPresent<T>(x: T): x is Exclude<T, undefined | null> {
  if (isNull(x)) {
    return false
  }
  if (typeof x === 'string') {
    return x !== ''
  }
  if (Array.isArray(x)) {
    return x.length > 0
  }

  return true
}

// null,undefined,falseのときにfalse
export function isTruthy(x: unknown): boolean {
  if (isNull(x) || x === false) {
    return false
  }
  return true
}

export function compact<T>(xs: T[]): Array<Exclude<T, undefined | null>> {
  return xs.filter((x) => isSome(x))
}

export const product =
  <X>(xs: X[]) =>
  <Y>(ys: Y[]): Array<[X, Y]> => {
    return xs.flatMap((x) => ys.map((y) => [x, y] as [X, Y]))
  }

type Ordering = -1 | 0 | 1
type CompareFunction<T> = (a: T, b: T) => Ordering
export const sortBy =
  <T>(compareFunctionsOrMapper: Array<CompareFunction<T>> | ((x: T) => unknown)) =>
  (xs: T[]): T[] => {
    if (!Array.isArray(compareFunctionsOrMapper)) {
      const mapper = compareFunctionsOrMapper
      return [...xs].sort((a, b) => compareFunction(mapper(a), mapper(b)))
    }

    const compareFunctions = compareFunctionsOrMapper
    if (compareFunctions.length === 0) {
      return xs
    }
    return [...xs].sort((a, b) => {
      for (const f of compareFunctions) {
        const result = f(a, b)
        if (result !== 0) {
          return result
        }
      }
      return 0
    })
  }

// reduceにより、同keyでの順序が保証されている
export const groupBy =
  <V, T>(f: (x: T) => V) =>
  (xs: T[]) => {
    return xs.reduce((accumulator, x) => {
      const key = f(x)
      const currentValue = accumulator.get(key) ?? []
      accumulator.set(key, [...currentValue, x])
      return accumulator
    }, new Map<V, T[]>())
  }

export const toArray = function <K, V>(xs: Map<K, V>) {
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(xs.entries())
}

export const objectToArray = function <T>(xs: Record<string, T>) {
  return Object.entries(xs).map(([k, v]) => [k, v] as [string, T])
}

export const toMap = function <K, V>(xs: Array<[K, V]>) {
  return xs.reduce((accumulator, property) => {
    accumulator.set(property[0], property[1])
    return accumulator
  }, new Map<K, V>())
}

// export const toRecord = <K extends string, V>(keys: K[], values: V[]) => {
//   let record = {} as { [k in K]: V }
//   for (let i = 0; i < keys.length; i++) {
//     const key = keys[i]
//     if (key === undefined) {
//       throw new Error()
//     }
//     const value = values[i]
//     record = {
//       ...record,
//       [key]: value,
//     }
//   }

//   return record
// }

export const sleep = async (sec: number) => {
  return await new Promise((resolve) => setTimeout(resolve, sec * 1000))
}

// endを含む
export const range = (start: number, end: number, step = 1) => {
  if (start > end) {
    return []
  }

  const xs: unknown[] = Array.from({ length: end - start + 1 })
  return [...xs].map((_, index) => start + index * step)
}

export const toObject = function <T>(xs: Readonly<Array<[string, T]>>) {
  return xs.reduce<Record<string, T>>((accumulator, property) => {
    accumulator[property[0]] = property[1]
    return accumulator
  }, {})
}

export const objectCompact = <T>(x: Record<string, T>) => {
  const xs = objectToArray(x).filter(([_key, value]) => isSome(value))
  const y = toObject(xs)
  return y as Record<string, Exclude<T, undefined | null>>
}

export const eachSlice = <T>(n: number) => {
  return (xs: T[]) => {
    if (n <= 0) {
      throw new Error(`eachSlice: negative number argument`)
    }

    const result: T[][] = []
    const ys = [...xs]
    while (ys.length > 0) {
      result.push(ys.splice(0, n))
    }
    return result
  }
}

export const uniqBy = <T>(f: (x: T) => string) => {
  return (xs: T[]) => {
    const object = Object.fromEntries(xs.map((x) => [f(x), x]))
    return Object.values(object)
  }
}

export const isDevelopmentOrTestEnv = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
export const isDevelopmentEnv = process.env.NODE_ENV === 'development'

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export function parseJsonIfValid(x: string): unknown | undefined {
  try {
    return JSON.parse(x) as unknown
  } catch {
    return undefined
  }
}
