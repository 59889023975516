import { isNull } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../../../../constant'
import type { ViewQueryMeasure, ViewQueryNode } from '../../../../../schemas/query'
import type {
  ViewConfigCustomKpiMeasure,
  ViewConfigKpiTimeSeries,
  ViewConfigPresetKpiMeasure,
} from '../../../../../schemas/view_config'
import { quote } from '../../../../query/executeViewQuery/generateSql'
import { flatNodes } from '../../../../query/executeViewQuery/util'
import type { CompileContext } from '../../../compileViewConfig'
import { generateFieldName } from '../../sheet/compileSheetViewConfigFields'
import { type CompileFilterArgument, compileMeasurePreset } from '../../sheet/measure/compileMeasure'
import { prioritizedTableName } from './utils'

export function generateWaterfallMeasure({
  config,
  queryNode,
  context,
}: {
  config: ViewConfigKpiTimeSeries
  queryNode: ViewQueryNode
  context: CompileContext
}): ViewQueryMeasure | undefined {
  const measure = config.kpiFragment?.measure
  if (isNull(measure)) {
    return
  }

  const sheet = config.kpiFragment?.sheet
  if (isNull(sheet) || sheet.type !== 'sheet') {
    return
  }

  const queryNodes = flatNodes(queryNode)
  const configNodes = flatNodes(sheet.tree)
  const presetCompileArgument = {
    queryNodes,
    configNodes,
    fields: [],
  }

  switch (measure.type) {
    case 'kpiPreset': {
      return generateKpiPresetMeasure({
        measure,
        argument: presetCompileArgument,
        context,
      })
    }
    case 'kpiCustom': {
      return generateKpiCustomMeasure({ measure })
    }
    default: {
      const x: never = measure
      throw new Error(`Unexpected measure type`)
    }
  }
}

function generateKpiPresetMeasure({
  measure,
  argument,
  context,
}: {
  measure: ViewConfigPresetKpiMeasure
  argument: CompileFilterArgument
  context: CompileContext
}): ViewQueryMeasure | undefined {
  const p1Measure = compileMeasurePreset(
    measure,
    {
      ...measure.property,
      nodeName: prioritizedTableName,
      propertyName: `${generateFieldName(measure.property)}_${CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX}`,
    },
    argument,
    context,
  )

  const p2Measure = compileMeasurePreset(
    measure,
    {
      ...measure.property,
      nodeName: prioritizedTableName,
      propertyName: `${generateFieldName(measure.property)}_${CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX}`,
    },
    argument,
    context,
  )

  if (isNull(p1Measure) || isNull(p2Measure)) {
    return
  }

  return {
    // 2つの期間の差分を集計する
    sql: `${p2Measure.sql} - ${p1Measure.sql}`,
    label: 'value',
    name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
  }
}

function generateKpiCustomMeasure({ measure }: { measure: ViewConfigCustomKpiMeasure }): ViewQueryMeasure | undefined {
  // SQL文字列の中からダブルクォートで囲まれたテーブル名・カラム名を抽出して置換する
  const p1MeasureSql = replaceQuotedTableAndColumnName(measure.sql, CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX)
  const p2MeasureSql = replaceQuotedTableAndColumnName(measure.sql, CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX)

  return {
    // 2つの期間の差分を集計する
    sql: `${p2MeasureSql} - ${p1MeasureSql}`,
    label: 'value',
    name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
  }
}

export const quotedFieldPattern = /"([^"]+)"\."([^"]+)"/g

function replaceQuotedTableAndColumnName(sql: string, suffix: string): string {
  return sql.replaceAll(quotedFieldPattern, (match, nodeName: string | undefined, propertyName: string | undefined) => {
    if (isNull(nodeName) || isNull(propertyName)) {
      return match
    }

    const columnName = `${generateFieldName({
      nodeName: nodeName.replaceAll('"', ''),
      propertyName: propertyName.replaceAll('"', ''),
    })}_${suffix}`
    return `${quote(prioritizedTableName)}.${quote(columnName)}`
  })
}
