import { isNull } from '@salescore/buff-common'

export const isSameObject = (as: object, bs: object) => {
  if (isNull(as) || isNull(bs)) {
    return isNull(as) && isNull(bs)
  }

  const sortedA = Object.entries(as).sort() // eslint-disable-line @typescript-eslint/require-array-sort-compare
  const sortedB = Object.entries(bs).sort() // eslint-disable-line @typescript-eslint/require-array-sort-compare
  if (sortedA.length !== sortedB.length) {
    return false
  }

  // eslint-disable-next-line unicorn/no-for-loop
  for (let index = 0; index < sortedA.length; index++) {
    const aRecord = sortedA[index]
    const bRecord = sortedB[index]
    if (aRecord === undefined || bRecord === undefined) {
      // ありえないはず
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [aKey, aValue] = aRecord
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [bKey, bValue] = bRecord
    if (aKey !== bKey) {
      return false
    }

    if (Array.isArray(aValue) && Array.isArray(bValue)) {
      if (!isSameArray(aValue, bValue)) {
        return false
      }
    } else if (typeof aValue === 'object' && typeof bValue === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!isSameObject(aValue, bValue)) {
        return false
      }
    } else if (isNull(aValue) && isNull(bValue)) {
      /* empty */
    } else if (JSON.stringify(aValue) !== JSON.stringify(bValue)) {
      return false
    }
  }

  return true
}

function isSameArray(as: unknown[], bs: unknown[]) {
  const sortedA = [...as].sort() // eslint-disable-line @typescript-eslint/require-array-sort-compare
  const sortedB = [...bs].sort() // eslint-disable-line @typescript-eslint/require-array-sort-compare
  if (sortedA.length !== sortedB.length) {
    return false
  }

  // eslint-disable-next-line unicorn/no-for-loop
  for (let index = 0; index < sortedA.length; index++) {
    const a = sortedA[index]
    const b = sortedB[index]

    if (Array.isArray(a) && Array.isArray(b)) {
      if (!isSameArray(a, b)) {
        return false
      }
    } else if (typeof a === 'object' && typeof b === 'object') {
      if (a === null || b === null) {
        if (a !== b) {
          return false
        }
        continue
      }

      if (!isSameObject(a, b)) {
        return false
      }
    } else if (JSON.stringify(a) !== JSON.stringify(b)) {
      return false
    }
  }

  return true
}

type Ordering = -1 | 0 | 1
const toOrdering = (x: number): Ordering => {
  if (x < 0) {
    return -1
  }
  if (x > 0) {
    return 1
  }
  return 0
}

const orderNullalbe = (a: unknown, b: unknown) => {
  const rankA = a === null ? 1 : a === undefined ? 2 : 0
  const rankB = b === null ? 1 : b === undefined ? 2 : 0
  return toOrdering(rankA - rankB)
}
export const compareFunction = (a: unknown, b: unknown): Ordering => {
  if (typeof a === 'number' && typeof b === 'number') {
    return toOrdering(a - b)
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return toOrdering(a.localeCompare(b))
  }
  if (a === undefined || b === undefined || a === null || b === null) {
    return orderNullalbe(a, b)
  }

  return toOrdering(JSON.stringify(a ?? null).localeCompare(JSON.stringify(b ?? null)))
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const parseJsonIfValid = (x: string): unknown | undefined => {
  try {
    return JSON.parse(x)
  } catch {
    return undefined
  }
}

export const generateRandomString = (length = 8): string => {
  // 生成する文字列に含める文字セット
  const chars = 'abcdefghijklmnopqrstuvwxyz'
  const charLength = chars.length
  let result = ''
  for (let index = 0; index < length; index++) {
    result += chars[Math.floor(Math.random() * charLength)]
  }
  return result
}

export function doubleQuote(x: string) {
  return `"${x}"`
}

export function isSharedLinkPresetName(x: string | undefined) {
  return x?.startsWith('[共有リンク]') ?? false
}

export function makeSharedLinkPresetName(x: string | null | undefined) {
  if (x === null || x === undefined) {
    return `[共有リンク]`
  }
  return `[共有リンク]${x}`
}
