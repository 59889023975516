import { z } from 'zod'

import { goalConfigSchema } from '../misc/goal_config'
import { selectOptionSchema } from '../query'
import { nodePropertyNameSchema } from './common'
import { viewConfigDimensionSchema } from './dimension'
import { viewConfigKpiSchema } from './kpi'
import { viewConfigSheetExtraPeriodSchema, ViewConfigSheetSchema } from './sheet'

// 週次や月次で集計したい時に、どの日付のスナップショットを利用するかの設定
const weeklyAggregationDaySchema = z.object({
  timeSpan: z.literal('week'),
  filterType: z.enum(['dayOfWeek']),
  filterValue: z.enum(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']),
})
const monthlyAggregationDaySchema = z.object({
  timeSpan: z.literal('month'),
  filterType: z.enum(['day']), // 一旦、日付のみ対応
  filterValue: z.string(),
})
const aggregationDaySchema = z.union([weeklyAggregationDaySchema, monthlyAggregationDaySchema])

export type WeeklyAggregationDay = z.infer<typeof weeklyAggregationDaySchema>
export type MonthlyAggregationDay = z.infer<typeof monthlyAggregationDaySchema>
export type AggregationDay = z.infer<typeof aggregationDaySchema>

const kpiFragmentSchema = viewConfigKpiSchema.merge(
  z.object({
    // prisma の View model の field を追加
    id: z.string(),
    name: z.string(),
    // 期間項目フィルタ
    dateY: z
      .object({
        property: nodePropertyNameSchema,
      })
      .optional(),
    aggregationDay: aggregationDaySchema.optional(),
  }),
)

export type KpiFragment = z.infer<typeof kpiFragmentSchema>

const goalConfigFragmentSchema = goalConfigSchema
  .merge(
    // goalConfigSchema の field を上書き
    z.object({
      userType: z.enum(['user']),
      goalDimension1: z.undefined(),
      goalDimension2: z.undefined(),
      goalDimension3: z.undefined(),
      goalDimension4: z.undefined(),
      goalDimension5: z.undefined(),
    }),
  )
  .merge(
    // prisma の GoalConfig model の field を追加
    z.object({
      organizationId: z.string(),
      userIdsConfig: z.array(z.string()),
      userGroupIdsConfig: z.array(z.literal('all')),
      config: z.any(),
    }),
  )

export type GoalConfigFragment = z.infer<typeof goalConfigFragmentSchema>

const designSettingsSchema = z.object({
  chartAxisLabelDisplayMode: z.enum(['auto', 'none', 'showAll']).optional(),
  chartValueLabelDisplayMode: z.enum(['auto', 'none', 'showAll']).optional(),
  chartValueLabelUnitType: z
    .enum(['noUnit', 'unitThousand', 'unitMillion', 'unitTenThousand', 'unitHandredMillion'])
    .optional(),
  chartValueLabelDecimalPlaceType: z
    .enum([
      'automatic',
      'noDecimalPlace',
      'firstDecimalPlace',
      'secondDecimalPlace',
      'thirdDecimalPlace',
      'fourthDecimalPlace',
    ])
    .optional(),
})

export type KpiTimeSeriesDesignSettings = z.infer<typeof designSettingsSchema>

const filtersSchema = z.object({
  dateY: viewConfigSheetExtraPeriodSchema.optional(),
  userOrUserGroupIds: z.string().array().optional(),
  userIds: z.string().array().optional(),
  breakdownProperties: z.string().or(z.number()).array().optional(),
  // 滝チャート用
  startPeriod: viewConfigSheetExtraPeriodSchema.optional(),
  endPeriod: viewConfigSheetExtraPeriodSchema.optional(),
})

export type KpiTimeSeriesFilters = z.infer<typeof filtersSchema>

export const waterfallDimensionSchema = z.object({
  // 一旦は固定として作るが、後々は自由に作れるようにしたい
  name: z.enum([
    'newOpportunity',
    'lostOpportunity',
    'timelineAdvanced',
    'timelineDelayed',
    'amountIncreased',
    'amountDecreased',
    'probabilityIncreased',
    'probabilityDecreased',
  ]),
  label: z.string(),
  property: nodePropertyNameSchema.optional(),
  extra: z
    .object({
      optionsOrder: selectOptionSchema.array(),
    })
    .partial()
    .optional(),
})

export type WaterfallDimension = z.infer<typeof waterfallDimensionSchema>

export const viewConfigKpiTimeSeriesSchema = z.object({
  // TODO: typeの名称をkpiTimeSeriesではなく、riなどに変更した方がいいかも
  type: z.literal('kpiTimeSeries'),
  kpiFragment: kpiFragmentSchema.optional(),
  goalConfigFragment: goalConfigFragmentSchema.optional(),
  period: viewConfigSheetExtraPeriodSchema.optional(),
  breakdownProperty: viewConfigDimensionSchema.optional(),
  designSettings: designSettingsSchema.optional(),
  filters: filtersSchema.optional(),
  // 滝チャート用
  waterfall: z
    .object({
      // 滝チャートのドリルダウンシートの設定はkpiFragment.sheetと異なるので別途保持する
      drillDownSheet: ViewConfigSheetSchema.optional(),
      dimensions: waterfallDimensionSchema.array(),
    })
    .optional(),
  option: z
    .object({
      asWaterfall: z.boolean().optional(),
    })
    .optional(),
})

export type ViewConfigKpiTimeSeries = z.infer<typeof viewConfigKpiTimeSeriesSchema>
