import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import type { CoreView } from '../../../schemas/View'
import type { ViewConfig, ViewConfigSheet } from '../../../schemas/view_config'
import { getCompanySheetView } from './companySheetView'
import { getOpportunitySheetView } from './opporunitySheetView'
import { getUserSheetView } from './userSheetView'
import { compileResourceWithoutViews } from './util'

export function getShoudanSakuseiKpiView() {
  // TODO
  const opportunitySheetView = getOpportunitySheetView()
  const sheetViews = [getCompanySheetView(), getUserSheetView(), opportunitySheetView]

  // eslint-disable-next-line unicorn/consistent-function-scoping
  function getViewByConfig(id: string, config: ViewConfig, views: CoreView[]): CoreView {
    const result = compileViewConfig(config, { ...compileResourceWithoutViews, views })

    return {
      id,
      name: id,
      config,
      queries: result!.queries,
      ui: result!.ui,
    }
  }

  return getViewByConfig(
    `商談数(作成)のviewId`,
    {
      type: `kpi`,
      sheet: {
        type: `reference`,
        viewId: opportunitySheetView.id,
      },
      measure: {
        type: `kpiPreset`,
        function: `count`,
        property: (opportunitySheetView.config as ViewConfigSheet).fields!.first()!.property, // TODO
      },
      ui: {
        kpi: {
          aggregationFunction: 'sum',
          showTransition: true,
        },
      },
    },
    sheetViews,
  )
}
