import { isNull, isSome } from '@salescore/buff-common'

import type { CoreModel } from '../../schemas/model/model'
import type { ModelProperty } from '../../schemas/model/modelProperty'
import type { CoreView } from '../../schemas/View'
import type {
  ViewConfigFilter,
  ViewConfigKpi,
  ViewConfigKpiPivot,
  ViewConfigKpiTimeSeries,
  ViewConfigSheet,
} from '../../schemas/view_config'
import { flatNodes } from '../query/executeViewQuery/util'
import { compileViewConfig, type OrganizaitonSetting } from '../view/compileViewConfig'
import type { CompileSheetAndMeasureResultExtra } from '../view/compileViewConfig/sheet/compileSheetAndMeasure'

const IGNORE_FILTER_TYPES = new Set(['include'])

export interface ModelIndex {
  modelName: string
  properties: string[]
}

export function generateIndicesFromView(
  view: CoreView,
  models: CoreModel[],
  views: CoreView[],
  organizationSetting: OrganizaitonSetting,
): ModelIndex[] {
  const type = view.config.type
  switch (type) {
    case 'sheet': {
      return generateIndicesFromSheet(view.config, models)
    }
    case 'kpi': {
      return generateIndicesFromKpi(view.config, models)
    }
    case 'kpiPivot': {
      return generateIndicesFromKpiPivot(view, view.config, models, views, organizationSetting)
    }
    case 'form': {
      return []
    }
    case 'kpiTimeSeries': {
      return generateIndicesFromKpiTimeSeries(view.config, models)
    }
    default: {
      throw new Error(type satisfies never)
    }
  }
}

// FIXME: 自動インデックス作成はフィルターだけでソートは対象ではなさそう？だとしたらRIにフィルター保存がないので不要？
function generateIndicesFromKpiTimeSeries(config: ViewConfigKpiTimeSeries, models: CoreModel[]): ModelIndex[] {
  if (config.kpiFragment?.sheet?.type !== 'sheet') {
    return []
  }
  return generateIndicesFromSheet(config.kpiFragment.sheet, models)
}

function generateIndicesFromSheet(config: ViewConfigSheet, models: CoreModel[]): ModelIndex[] {
  const filters: ViewConfigFilter[] = flatNodes(config.filterTree).flatMap((x) => x.leafs)
  const tableAndColumns = filters.map((filter) => {
    if (filter.type !== 'property') {
      return
    }
    if (IGNORE_FILTER_TYPES.has(filter.filterType)) {
      return
    }

    const eltModel: CoreModel | undefined = models.find((x) => x.name === filter.property.modelName)
    if (eltModel === undefined) {
      return
    }

    const property: ModelProperty | undefined = findIndexableProperty(eltModel, filter.property.propertyName)
    if (property === undefined) {
      return
    }

    return {
      eltModelName: eltModel.name,
      propertyName: property.name,
    }
  })

  return tableAndColumns
    .compact()
    .groupBy((x) => x.eltModelName)
    .map(
      (modelName, properties): ModelIndex => ({
        modelName,
        properties: properties
          .map((x) => x.propertyName)
          .unique()
          .sortBy((x) => x),
      }),
    )
}

function generateIndicesFromKpi(config: ViewConfigKpi, models: CoreModel[]): ModelIndex[] {
  const sheet = config.sheet
  if (isNull(sheet) || sheet.type !== 'sheet') {
    return []
  }
  const sheetIndices = generateIndicesFromSheet(sheet, models)
  const userIndex: ModelIndex | undefined = isSome(config.user)
    ? {
        modelName: config.user.property.modelName,
        properties: [config.user.property.propertyName],
      }
    : undefined
  const dateIndex: ModelIndex | undefined = isSome(config.date)
    ? {
        modelName: config.date.property.modelName,
        properties: [config.date.property.propertyName],
      }
    : undefined

  return [
    userIndex,
    dateIndex,
    ...sheetIndices,
    ...(userIndex === undefined
      ? []
      : sheetIndices.map((sheetIndex): ModelIndex | undefined => {
          // sheetIndexのmodelNameとuserIndexのmodelNameが一致する場合は、userIndexのpropertyNameを追加する
          if (sheetIndex.modelName !== userIndex.modelName) {
            return undefined
          }
          return {
            modelName: sheetIndex.modelName,
            properties: [...userIndex.properties, ...sheetIndex.properties], // userの絞り込みを優先
          }
        })),
    ...(dateIndex === undefined
      ? []
      : sheetIndices.map((sheetIndex): ModelIndex | undefined => {
          // sheetIndexのmodelNameとdateIndexのmodelNameが一致する場合は、dateIndexのpropertyNameを追加する
          if (sheetIndex.modelName !== dateIndex.modelName) {
            return undefined
          }
          return {
            modelName: sheetIndex.modelName,
            properties: [...dateIndex.properties, ...sheetIndex.properties], // dateの絞り込みを優先
          }
        })),
  ].compact()
}

function generateIndicesFromKpiPivot(
  view: CoreView,
  config: ViewConfigKpiPivot,
  models: CoreModel[],
  views: CoreView[],
  organizationSetting: OrganizaitonSetting,
): ModelIndex[] {
  const presets = config.presets ?? []
  if (presets.isBlank()) {
    return []
  }
  const indices = presets.flatMap((preset): ModelIndex[] => {
    // const leafs = preset.parameter.dimensionFilterLeafs ?? []
    // const dimensionLeafs = leafs.map((leaf) => (leaf.type === 'dimension' ? leaf : undefined)).compact()
    const dimensions = [
      ...preset.parameter.pivot.rows,
      ...preset.parameter.pivot.columns,
      // ...dimensionLeafs.map((d) => d.dimension), // TODO: 本当はフィルタまで含めて最適化したいができていない
    ]
    if (dimensions.isBlank()) {
      return []
    }

    const result = compileViewConfig(
      config,
      {
        view: { id: ``, name: `` },
        models,
        views,
        organizationSetting,
      },
      {
        additionalConfig: {
          kpiParameter: preset.parameter,
        },
      },
    )
    const filteredDimensionPropertiesGroups: ModelPropertyPair[][] | undefined = result?.extra
      ?.map((extra: CompileSheetAndMeasureResultExtra) => {
        const filtered: ModelPropertyPair[] = [...extra.filterLeafProperties, ...extra.dimensionProperties].filter(
          (x) => x.propertyName !== `id`,
        )
        if (filtered.isBlank()) {
          return
        }
        return filtered
      })
      .compact()
      .uniqueBy((x) => JSON.stringify(x))
    if (filteredDimensionPropertiesGroups === undefined) {
      return []
    }
    return buildIndicesFromDimensionProperties(filteredDimensionPropertiesGroups, models)
  })
  return indices.uniqueBy((x) => JSON.stringify(x)) // 雑だが、これでuniqueになるはず
}

export interface ModelPropertyPair {
  modelName: string
  propertyName: string
}

export function buildIndicesFromDimensionProperties(
  filteredDimensionPropertiesGroups: ModelPropertyPair[][],
  models: CoreModel[],
): ModelIndex[] {
  return filteredDimensionPropertiesGroups.flatMap((filteredDimensionProperties): ModelIndex[] =>
    filteredDimensionProperties
      .groupBy((x) => x.modelName)
      .map((modelName, properties): ModelIndex | undefined => {
        const eltModel: CoreModel | undefined = models.find((x) => x.name === modelName)
        if (eltModel === undefined) {
          // 実際の処理ではCoreModelが存在しないmodelNameが来ることはないはず。例外のほうが適切？
          return undefined
        }
        return {
          modelName,
          properties: properties
            .map((x) => x.propertyName)
            .unique()
            .filter((p) => findIndexableProperty(eltModel, p)),
        }
      })
      .compact(),
  )
}

function findIndexableProperty(eltModel: CoreModel, propertyName: string): ModelProperty | undefined {
  // DBのtextカラムは長すぎるとインデックスが作れなくてエラーになるので長文テキストは除外する
  return eltModel.properties.find((property) => property.name === propertyName && property.meta !== 'text')
}
