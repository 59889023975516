import { PRESET_MODELS } from '../../../../constant/presetModels'
import type { ViewQuery, ViewQueryList } from '../../../../schemas/query'
import type { ViewConfigSheet } from '../../../../schemas/view_config'
import { flatNodes } from '../../../query/executeViewQuery/util'
import type { CompileContext, CompileViewConfigResponse } from '../../compileViewConfig'
import { updateConfigByAdditionalConfig } from '../kpi/updateConfigByAdditionalConfig'
import { compileSheetAndMeasure } from './compileSheetAndMeasure'
import { compileSheetViewConfigFields } from './compileSheetViewConfigFields'
import { compileSheetViewConfigFilterTree } from './compileSheetViewConfigFilterTree'
import { compileSheetViewConfigSorters } from './compileSheetViewConfigSorters'
import { compileSheetViewConfigTree } from './compileSheetViewConfigTree'
import { expandChildrenByReferenceField } from './expandChildrenByReferenceField'
import { pruneSheetConfigTree } from './pruneSheetConfigTree'

// TODO
const userStream = {
  name: `salescore_users`,
  destination: {
    name: `salescore_users`,
  },
  meta: {
    label: `ユーザー`,
  },
}
const emptyQuery: ViewQuery = {
  type: 'list',
  tree: {
    name: userStream.name,
    path: [userStream.name],
    read: {
      idColumn: 'id', // TODO: idが常にある前提。カスタムストリームなど、idがない場合にどうするか？destinationで指定すべきか？
      tableSql: userStream.destination.name,
      tableType: 'table',
    },
    write: {
      streamName: userStream.name,
    },
    meta: {
      label: userStream.meta.label,
      dependedStreamNames: [userStream.name],
    },
  },
  fields: [],
  sorters: [],
  filterTree: {
    logicalOperator: 'and',
    leafs: [],
    children: [],
  },
}

export function compileSheetViewConfig(configRaw: ViewConfigSheet, context: CompileContext): CompileViewConfigResponse {
  const empty = {
    queries: [emptyQuery], // 本来はここを空にしたいが、初期設計のミスで空が許されないため、適当な値をいれている
    query: emptyQuery,
    ui: [
      {
        type: 'Sheet' as const,
        columns: [],
        fixedLeftColumnIndex: undefined,
        visibleRowNum: undefined,
      },
    ],
    context,
  }
  const config: ViewConfigSheet = updateConfigByAdditionalConfig(configRaw, context)
  const prunedTree = pruneSheetConfigTree(config)
  const expandedTree = expandChildrenByReferenceField(prunedTree, config, context)
  const tree = compileSheetViewConfigTree(expandedTree, context)
  if (tree === undefined || expandedTree === undefined) {
    return empty
  }
  const queryNodes = flatNodes(tree)
  const configNodes = flatNodes(expandedTree)
  const fieldsAndColumns = compileSheetViewConfigFields(config.fields, queryNodes, configNodes, context)
  if (fieldsAndColumns.isBlank()) {
    return empty
  }
  const fields = fieldsAndColumns.map((x) => x.queryField)
  const listQuery: ViewQueryList = {
    type: 'list',
    tree,
    fields,
    filterTree: compileSheetViewConfigFilterTree(config.filterTree, queryNodes, configNodes, fields, context, config),
    sorters: compileSheetViewConfigSorters(config, queryNodes, context),
    extra: {
      searchQuery: config.meta?.searchQuery,
      snapshotConfigs: fieldsAndColumns.flatMap((x) => x.snapshotConfigs).uniqueBy((x) => JSON.stringify(x)), // 雑にuniqueByしておく
    },
  }
  const measures = (config.fields ?? []).flatMap((field) => field.measures ?? [])
  const aggregationQueries = measures
    .map((measure) => compileSheetAndMeasure(measure, config, undefined, [], context))
    .compact()
  const queries: ViewQuery[] = [listQuery, ...aggregationQueries.map((x) => x.query)].compact()

  const columns = fieldsAndColumns
    .filter(
      (x) =>
        // TODO: addSalescoreUserFieldsにより、salescore_users_with_groupのid系フィールドが追加されるが、表示の時は不要なのでやや無理やりここで弾いている
        !(
          x.configField.property.modelName === PRESET_MODELS.USER_WITH_USER_GROUP.NAME &&
          x.configField.property.propertyName.endsWith('id')
        ),
    )
    .map((x) => x.column)

  return {
    queries,
    query: queries[0]!,
    ui: [
      {
        type: 'Sheet',
        columns,
        fixedLeftColumnIndex: config.meta?.fixedLeftColumnIndex,
        visibleRowNum: config.meta?.visibleRowNum,
        isGoalView: config.meta?.isGoalView,
      },
    ],
    context,
  }
}
