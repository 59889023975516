import type { CoreModel } from '../../schemas/model/model'
import type { ModelProperty } from '../../schemas/model/modelProperty'

// 本当は存在しないが、ロジック上必用になるので無理やりいれてる
export const USER_GROUP_MODEL_ID_PROPERTY: ModelProperty = {
  name: 'id',
  label: `ID`,
  type: 'string',
  meta: 'id',
}

export const SALESCORE_USER_GROUP_MODEL: CoreModel = {
  icon: 'faUsers',
  name: 'salescore_user_groups',
  label: 'グループ',
  write: {
    sourceEntityName: 'salescore_user_groups',
  },
  properties: [
    USER_GROUP_MODEL_ID_PROPERTY,
    {
      name: 'name',
      type: 'string',
      label: 'グループ名',
      meta: 'name',
    },
    {
      name: 'user_group_id',
      type: 'string',
      label: '親グループID',
      referenceTo: [
        {
          modelName: `salescore_user_groups`,
        },
      ],
    },
    {
      name: 'depth',
      type: 'integer',
      label: '階層',
    },
    {
      name: 'rank',
      type: 'integer',
      label: '階層',
    },
  ],
}
