import type { CoreModel } from '../../schemas/model/model'
import type { ModelProperty } from '../../schemas/model/modelProperty'

// 本当は存在しないが、ロジック上必用になるので無理やりいれてる
export const USER_MODEL_ID_PROPERTY: ModelProperty = {
  name: 'id',
  label: `ID`,
  type: 'string',
  meta: 'id',
}

export const SALESCORE_USER_MODEL: CoreModel = {
  icon: 'faUser',
  name: 'salescore_users',
  label: 'ユーザー',
  write: {
    sourceEntityName: 'salescore_users',
  },
  properties: [
    USER_MODEL_ID_PROPERTY,
    {
      meta: 'name',
      name: 'name',
      type: 'string',
      label: 'ユーザー名',
    },
    {
      name: 'rank',
      type: 'numeric',
      label: '並び順(昇順)',
    },
    {
      meta: 'email',
      name: 'email',
      type: 'string',
      label: 'メールアドレス',
    },
    {
      meta: 'url',
      name: 'image_url',
      type: 'string',
      label: 'プロフィール画像',
    },
    {
      meta: 'relation',
      name: 'user_group_id',
      type: 'string',
      label: '所属グループ',
      referenceTo: [
        {
          modelName: 'user_groups',
        },
      ],
    },
    {
      name: 'visibility',
      type: 'boolean',
      label: '表示設定',
    },
    {
      name: 'salescore_user_id',
      type: 'string',
      label: 'salescore_user_id',
    },
    {
      name: 'salescore_invitation_id',
      type: 'string',
      label: 'salescore_invitation_id',
    },
    {
      name: 'salesforce_user_id',
      type: 'string',
      label: 'salesforce_user_id',
    },
    {
      name: 'hubspot_owner_id',
      type: 'string',
      label: 'hubspot_owner_id',
    },
    {
      name: 'hubspot_user_id',
      type: 'string',
      label: 'hubspot_user_id',
    },
    {
      name: 'zoho_user_id',
      type: 'string',
      label: 'zoho_user_id',
    },
    {
      name: 'next_sfa_user_id',
      type: 'string',
      label: 'next_sfa_user_id',
    },
    {
      name: 'google_sheets_user_id',
      type: 'string',
      label: 'google_sheets_user_id',
    },
    {
      name: 'comdesk_user_id',
      type: 'string',
      label: 'comdesk_user_id',
    },
    {
      name: 'senses_user_id',
      type: 'string',
      label: 'senses_user_id',
    },
    {
      name: 'microsoft_dynamics_user_id',
      type: 'string',
      label: 'microsoft_dynamics_user_id',
    },
    {
      name: 'big_query_user_id',
      type: 'string',
      label: 'big_query_user_id',
    },
    {
      name: 'created_at',
      type: 'datetime',
      label: '作成時刻',
    },
    {
      name: 'updated_at',
      type: 'datetime',
      label: '更新時刻',
    },
    {
      name: 'deleted_at',
      type: 'datetime',
      label: '削除時刻',
    },
  ],
}
