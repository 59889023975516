import type { ViewQueryNode, ViewQuerySorter } from '../../../../schemas/query'
import type { ViewConfigSheet } from '../../../../schemas/view_config'
import type { CompileContext } from '../../compileViewConfig'

export function compileSheetViewConfigSorters(
  config: ViewConfigSheet,
  nodes: ViewQueryNode[],
  context: CompileContext,
): ViewQuerySorter[] {
  const sorters: ViewConfigSheet['sorters'] = config.sorters
  if (sorters === undefined) {
    return []
  }

  return sorters
    .map((sorter): ViewQuerySorter | undefined => {
      // fieldに存在しないsorterが定義されてしまった場合除外する(通常あり得ない)
      if (
        config.fields?.find(
          (field) =>
            field.property.nodeName === sorter.property.nodeName &&
            field.property.propertyName === sorter.property.propertyName,
        ) === undefined
      ) {
        return undefined
      }
      const node = nodes.find((node) => node.name === sorter.property.nodeName)
      const property = context.modelSearcher.searchProperty(sorter.property.modelName, sorter.property.propertyName)
      if (node === undefined || property === undefined) {
        return undefined
      }

      return {
        nodePaths: [node.path],
        read: {
          sql: `"${node.name}"."${property.name}"`, // TODO: このレイヤーでSQL変換したくない
          order: sorter.order,
        },
        meta: {
          label: [property.label, sorter.order === 'desc' ? ' 降順' : ''].join(''),
          dependedPropertyNamesWithStreamName: [
            {
              streamName: sorter.property.modelName,
              propertyName: sorter.property.propertyName,
            },
          ],
        },
      }
    })
    .compact()
}
