import { LoginOutlined } from '@ant-design/icons'
import { useFetchAdminOrganizationsQuery } from '@salescore/admin-client-api'

import { useMoveCommands } from './useMoveCommands'

export type LauncherCommandType = 'all' | 'sheet' | 'kpiPivot' | 'page' | 'other' | 'kpi'
export interface LauncherCommandV2 {
  icon: JSX.Element
  title: string
  subTitle?: string
  type: LauncherCommandType
  searchKeyword?: string
  shortcut?: string[]
  method: () => void | Promise<void>
  loading?: boolean
  options?: {
    hideModalAfterExecution?: boolean // default true
  }
}

export const useLauncherCommands = ({ redirect }: { redirect: (path: string) => void }): LauncherCommandV2[] => {
  const moveCommands = useMoveCommands({ redirect })
  const organizationCommands = useMoveToOrganizationCommands({ redirect })
  return [...moveCommands, ...organizationCommands]
}

function useMoveToOrganizationCommands({ redirect }: { redirect: (path: string) => void }): LauncherCommandV2[] {
  const fetchAdminOrganizationsQuery = useFetchAdminOrganizationsQuery({})
  const adminOrganizations = fetchAdminOrganizationsQuery.data?.adminOrganizations ?? []

  return adminOrganizations.map((o) => {
    return {
      type: `other`,
      icon: <LoginOutlined />,
      title: `移動: ${o.name}`,
      searchKeyword: `move ${o.name.toLowerCase()}`,
      // shortcut: ['command', 'd'],
      method: () => {
        // 再現手順は不明だが、組織の移動の際に稀に移動前の組織の情報が残ってしまう不具合があったので
        // 組織移動の時のみredirectではなくlocation.assignを使うように変更
        location.assign(`/o/${o.id}`)
      },
    }
  })
}
