import { z } from 'zod'

import { type ViewUIComponent, viewUISchema } from './component'

//
// grid
//
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewUIRow = {
  type: 'Row'
  id: string
  gutter?: number
  children: ViewUICol[]
  // justify: "space-between" // など
}

// export type ViewUIRow = z.infer<typeof rowSchema>

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ViewUICol = {
  type: 'Col'
  id: string
  span: number
  children: ViewUIComponent[]
}

// export type ViewUICol = z.infer<typeof colSchema>

export const rowSchema: z.ZodSchema<ViewUIRow> = z.lazy(() =>
  z.object({
    type: z.literal('Row'),
    id: z.string(),
    gutter: z.number().optional(),
    children: colSchema.array(),
  }),
)

export const colSchema: z.ZodSchema<ViewUICol> = z.lazy(() =>
  z.object({
    type: z.literal('Col'),
    id: z.string(),
    span: z.number(),
    children: viewUISchema,
  }),
)
