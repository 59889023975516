import { isNull } from '@salescore/buff-common'

import type { ViewQueryNode } from '../../../../../schemas/query'
import type { ViewConfigKpiTimeSeries } from '../../../../../schemas/view_config'
import type { CompileContext } from '../../../compileViewConfig'
import { generatePrioritizedSql } from './generatePrioritizedSql'
import { generateSequenceJoinedSnapshotSql } from './generateSequenceJoinedSnapshotSql'
import { prioritizedTableName, snapshotP1Name, snapshotP2Name } from './utils'

export function generateWaterfallQueryNode(
  config: ViewConfigKpiTimeSeries,
  context: CompileContext,
): ViewQueryNode | undefined {
  const startAt = config.filters?.startPeriod?.startAt
  const endAt = config.filters?.endPeriod?.endAt
  if (isNull(startAt) || isNull(endAt)) {
    return
  }

  // 2つの期間のスナップショットをWITH句として生成する
  const snapshotP1Sql = generateSequenceJoinedSnapshotSql({
    config,
    context,
    sequenceCreatedAt: startAt,
  })
  const snapshotP2Sql = generateSequenceJoinedSnapshotSql({
    config,
    context,
    sequenceCreatedAt: endAt,
  })

  // 2つの期間のスナップショットを結合させた上で、集計優先度のキーとなる列を追加したSQLを生成する
  const prioritizedSnapshotSql = generatePrioritizedSql({ config, context })

  if (isNull(snapshotP1Sql) || isNull(snapshotP2Sql) || isNull(prioritizedSnapshotSql)) {
    return
  }

  return {
    name: prioritizedTableName,
    path: [prioritizedTableName],
    read: {
      tableType: 'table',
      tableSql: prioritizedTableName,
      idColumn: 'id',
    },
    meta: {
      label: prioritizedTableName,
      dependedStreamNames: [],
    },
    children: [
      {
        name: snapshotP1Name,
        path: [prioritizedTableName, snapshotP1Name],
        read: {
          tableType: 'subquery',
          tableSql: snapshotP1Sql,
          idColumn: 'id',
        },
        meta: {
          label: snapshotP1Name,
          dependedStreamNames: [],
        },
      },
      {
        name: snapshotP2Name,
        path: [prioritizedTableName, snapshotP2Name],
        read: {
          tableType: 'subquery',
          tableSql: snapshotP2Sql,
          idColumn: 'id',
        },
        meta: {
          label: snapshotP2Name,
          dependedStreamNames: [],
        },
      },
      {
        name: prioritizedTableName,
        path: [prioritizedTableName, prioritizedTableName],
        read: {
          tableType: 'subquery',
          tableSql: prioritizedSnapshotSql,
          idColumn: 'id',
        },
        meta: {
          label: prioritizedTableName,
          dependedStreamNames: [],
        },
      },
    ],
  }
}
