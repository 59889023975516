import { z } from 'zod'

import { coreDslFormNodeSchema } from '../../dsl'
import { viewQueryFieldSchema } from '../query'
import { viewUiSheetColumnSchema } from '../ui/ui'
import { nodePropertyNameSchema } from './common'
import { viewConfigMeasureSchema } from './measure'

export const queryFieldSchemaForOverride = z
  .object({
    read: viewQueryFieldSchema.shape.read.partial().optional().nullable(),
    write: viewQueryFieldSchema.shape.write.optional().nullable(),
    meta: viewQueryFieldSchema.shape.meta.partial().optional().nullable(),
  })
  .optional()
  .nullable()

const highlightColorSchema = z.object({
  color: z.string(),
  label: z.string().optional(),
})
export type HighlightColor = z.infer<typeof highlightColorSchema>
export const conditionalEffectConfigSchema = z.union([
  z.object({
    // configのレイヤーでは単純に式を文字列で保持し、query,uiのレイヤーでcompile済みのASTを扱う
    type: z.literal('expressionText').optional(),
    expression: z.string(),
    effect: z.enum(['disable', `emphasis`]),
  }),
  z.object({
    // 2023/05 上記のみでいこうとしていたが、結局フォームを作り、またフォーム側で独自のASTが必要だったため、
    //         フォーム側のASTをそのまま保持する形にした。
    // formAst -> coreAst という形で変換されて使われる
    type: z.literal('form'),
    ast: coreDslFormNodeSchema,
    effect: z.enum(['highlight']),
    highlightColor: highlightColorSchema.optional(),
  }),
])
export type ConditionalEffectConfig = z.infer<typeof conditionalEffectConfigSchema>

export const viewConfigFieldSchema = z.object({
  type: z.literal(`property`), // customも許してunionにする想定
  property: nodePropertyNameSchema,
  measures: viewConfigMeasureSchema.array().optional(),
  conditionalEffects: conditionalEffectConfigSchema.array().optional(),
  override: z
    .object({
      queryField: queryFieldSchemaForOverride,
      uiColumn: viewUiSheetColumnSchema.partial().optional().nullable(),
      // derpecated。overrideはそのまま上書きするものだけにしたい。
      conditionalEffects: conditionalEffectConfigSchema.array().optional().nullable(),
    })
    .optional(),
})
export type ViewConfigField = z.infer<typeof viewConfigFieldSchema>
