import { isNull, isPresent } from '../..'
import type { ValidationArgument, ValidationFunction } from './schema'

export function validate(record: unknown, validationFunction: ValidationFunction): boolean {
  switch (validationFunction.functionType) {
    case 'present': {
      return presentValidation(record, validationFunction.arguments)
    }
    case 'equal': {
      return equalValidation(record, validationFunction.arguments)
    }
    case 'in': {
      return inValidation(record, validationFunction.arguments)
    }
    case 'never': {
      return false
    }
    default: {
      throw new Error(validationFunction.functionType satisfies never)
    }
  }
}

function presentValidation(record: unknown, arguments_: ValidationArgument[]): boolean {
  const recordValue = getRecordValue(record, arguments_[0])
  if (recordValue === undefined) {
    return false
  }
  return isPresent(recordValue)
}

function equalValidation(record: unknown, arguments_: ValidationArgument[]): boolean {
  const recordValue = getRecordValue(record, arguments_[0])
  if (recordValue === undefined) {
    return false
  }
  const value = arguments_[1]
  if (value === undefined || value.type !== 'value') {
    return false
  }

  return recordValue === value.value
}

function inValidation(record: unknown, arguments_: ValidationArgument[]): boolean {
  const recordValue = getRecordValue(record, arguments_[0])
  if (recordValue === undefined) {
    return false
  }
  const values = getArrayValue(arguments_[1])
  if (values === undefined) {
    return false
  }
  return values.includes(recordValue)
}

function getRecordValue(record: unknown, argument: ValidationArgument | undefined) {
  // TODO: schemaがおかしいときはどうするか？
  if (isNull(argument)) {
    return
  }
  if (argument.type !== 'recordValue') {
    return
  }

  return getValue(record, argument.path)
}

function getArrayValue(argument: ValidationArgument | undefined): unknown[] | undefined {
  // TODO: schemaがおかしいときはどうするか？
  if (isNull(argument)) {
    return undefined
  }
  if (argument.type !== 'value') {
    return undefined
  }
  const value = argument.value
  if (Array.isArray(value)) {
    return value as unknown[]
  }
  return undefined
}

function getValue(record: unknown, path: string[]): unknown {
  const attribute = path[0]
  if (isNull(attribute)) {
    // path.isBlank()のとき
    return record
  }
  if (typeof record !== 'object' || isNull(record)) {
    return undefined
  }

  const value = (record as Record<string, unknown>)[attribute]

  return getValue(value, path.slice(1))
}
