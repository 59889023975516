// ブラウザでも使うため、nodejsのBufferではなく、bufferモジュールのBufferを使う
// eslint-disable-next-line unicorn/prefer-node-protocol
import { Buffer } from 'buffer'
// eslint-disable-next-line unicorn/prefer-node-protocol
import crypto from 'crypto'

import { POSTGRES_COLUMN_LENGTH_LIMIT } from '../query/executeViewQuery'

// 以下、既存のテーブルでテーブル名を壊したくなかったので維持する
const ignoreTables = new Set([
  'kintone_【法人】活動登録_他社同行者テーブル',
  'kintone_【法人】活動登録_ターゲットテーブル',
  'salesforce_engage_reports___engage__email__template___share',
])

// 文字列sのmaxByteまでをidentifierとして使うとき、
// 文字列がuniqueになるように加工して返す
// （postgresが63バイト分以降の文字列を無視してしまうので、これを防ぐための対応）
export function makeIdentifiable(s: string, maxByte?: number): string {
  if (ignoreTables.has(s)) {
    return s
  }

  if (isIdentifiable(s, maxByte)) {
    return s
  }

  const digest = crypto.createHash('md5').update(s).digest('hex') // 32文字
  return [extractHead(s, 32), digest.slice(0, 8)].join('_') // 8文字分のハッシュで衝突する可能性は十分少ないという仮定
}

export function isIdentifiable(s: string, maxByte?: number): boolean {
  const length = Buffer.byteLength(s)
  return length <= (maxByte ?? POSTGRES_COLUMN_LENGTH_LIMIT)
}

// maxByteになるまで、sの先頭から文字列を取得
function extractHead(s: string, maxByte: number): string {
  // eslint-disable-next-line unicorn/prefer-spread
  const chars = s.split('')
  let current = ''
  for (const char of chars) {
    const next = [current, char].join('')
    if (Buffer.byteLength(next) > maxByte) {
      return current
    }
    current = next
  }
  return current
}
