import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import type { CoreView } from '../../../schemas/View'
import type { ViewConfigField, ViewConfigSheet } from '../../../schemas/view_config'
import { companyModel } from '../models/companyModel'
import { contactModel } from '../models/contactModel'
import { opportunityModel } from '../models/opportunityModel'
import { compileResourceWithoutViews } from './util'

//
// companyを親として、contact, opportunityが紐づいたシート
//
export function getCompanySheetView(option?: {
  filterTree?: ViewConfigSheet['filterTree']
}): CoreView & { config: ViewConfigSheet } {
  const companyFields = companyModel.properties.map(
    (property): ViewConfigField => ({
      type: 'property',
      property: {
        nodeName: companyModel.name,
        modelName: companyModel.name,
        propertyName: property.name,
      },
      measures:
        property.name === 'name'
          ? [
              {
                type: 'preset',
                name: 'count',
                label: 'レコード数',
                function: 'count',
                fieldName: `companies_employees`,
              },
            ]
          : property.name === 'employees'
            ? [
                {
                  type: 'preset',
                  name: 'sumEmployees',
                  label: '合計',
                  function: 'sum',
                  fieldName: `companies_employees`,
                },
              ]
            : [],
    }),
  )
  const contactFields = contactModel.properties.map(
    (property): ViewConfigField => ({
      type: 'property',
      property: {
        nodeName: contactModel.name,
        // nodeName: `companies_created_by`,
        modelName: contactModel.name,
        propertyName: property.name,
      },
      measures:
        property.name === 'name'
          ? [
              {
                type: 'preset',
                name: 'contact_name_count',
                label: 'レコード数',
                function: 'count',
                fieldName: `contact_name`,
              },
            ]
          : [],
    }),
  )
  const opportunityFields = opportunityModel.properties.map(
    (property): ViewConfigField => ({
      type: 'property',
      property: {
        nodeName: opportunityModel.name,
        // nodeName: `companies_created_by`,
        modelName: opportunityModel.name,
        propertyName: property.name,
      },
      measures:
        property.name === 'name'
          ? [
              {
                type: 'preset',
                name: 'opportunity_name_count',
                label: 'レコード数',
                function: 'count',
                fieldName: `opportunity_name`,
              },
            ]
          : [],
    }),
  )
  const companySheetViewConfig: ViewConfigSheet = {
    type: 'sheet',
    tree: {
      type: 'model',
      name: companyModel.name,
      modelName: companyModel.name,
      children: [
        {
          type: 'model',
          name: contactModel.name,
          modelName: contactModel.name,
          referenceToProperty: {
            nodeName: contactModel.name,
            modelName: contactModel.name,
            propertyName: `company_id`,
            referenceTo: {
              modelName: companyModel.name,
            },
          },
          children: [
            {
              type: 'model',
              name: opportunityModel.name,
              modelName: opportunityModel.name,
              referenceToProperty: {
                nodeName: opportunityModel.name,
                modelName: opportunityModel.name,
                propertyName: `contact_id`,
                referenceTo: {
                  modelName: contactModel.name,
                },
              },
            },
          ],
        },
      ],
    },
    fields: [...companyFields, ...contactFields, ...opportunityFields],
    filterTree: option?.filterTree,
    // filterTree: {
    //   logicalOperator: 'and',
    //   children: [],
    //   leafs: [
    //     {
    //       type: 'property',
    //       property: {
    //         nodeName: companyModel.name,
    //         modelName: companyModel.name,
    //         propertyName: `name`,
    //       },
    //       filterType: `equal`,
    //       filterValueParameterName: `MUSTACHE_NAME`,
    //     },
    //   ],
    // },
  }

  const result = compileViewConfig(companySheetViewConfig, compileResourceWithoutViews)

  return {
    id: 'companySheetView',
    name: 'companySheetView',
    config: companySheetViewConfig,
    queries: result!.queries,
    ui: result!.ui,
  }
}
