import { compileDeprecatedFunction, compileFunction } from './compile/compileFunction'
import { compileOperator } from './compile/compileOperator'
import type { ExpressionNodeWithDeprecated } from './syntax/expression_ast'

export type CoreDslParameter = Record<string, unknown>

export function compileExpression(node: ExpressionNodeWithDeprecated, variable: CoreDslParameter): unknown {
  const type = node.type
  switch (type) {
    case 'literal': {
      return node.value
    }
    case 'variable': {
      return dig(variable, node.path)
    }
    case 'func': {
      return compileFunction(node, variable)
    }
    case 'array': {
      return node.elements.map((x) => compileExpression(x, variable))
    }
    case 'operator': {
      return compileOperator(node, variable)
    }
    // deprecated
    case 'function': {
      return compileDeprecatedFunction(node, variable)
    }
    case 'value': {
      return node.value
    }
    case 'recordValue': {
      return dig(variable, node.path)
    }
    default: {
      throw new Error(type satisfies never)
    }
  }
}

function dig(x: unknown, path: string[]): unknown {
  if (path.length === 0) {
    return x
  }
  if (typeof x !== 'object' || x === null) {
    return undefined // TODO
  }
  const object = x as Record<string, unknown>
  const p = path[0]!
  return dig(object[p], path.slice(1))
}

export function isTruthy(x: unknown) {
  if (typeof x === 'boolean') {
    return x
  }
  if (x === null || x === undefined) {
    return false
  }
  return true
}
