import { useEffect } from 'react'
import {
  type RecoilState,
  type RecoilValueReadOnly,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'

export const buildReadonlyAtom = <T>(key: string, atom: RecoilState<T>) => {
  return selector<T>({
    key,
    get: ({ get }) => {
      return get(atom)
    },
  })
}

export const buildBooleanHook = (atom: RecoilState<boolean>) => {
  return () => {
    const [flag, setFlag] = useRecoilState(atom)

    return {
      isTrue: flag,
      isFalse: !flag,
      toggle() {
        setFlag((x) => !x)
      },
      setTrue() {
        setFlag(true)
      },
      setFalse() {
        setFlag(false)
      },
    }
  }
}

export const buildReadonlyHook = <T>(atom: RecoilState<T> | RecoilValueReadOnly<T>) => {
  return () => useRecoilValue(atom)
}

export const buildStateHook = <T>(atom: RecoilState<T>) => {
  return () => useRecoilState(atom)
}

export const buildInitializePropHook = <T>(atom: RecoilState<T>) => {
  return (value: T) => {
    const setActions = useSetRecoilState(atom)
    useEffect(() => {
      setActions(value)
    }, [value])
  }
}

export interface ModalAtomType<T> {
  visible: boolean
  content: T | undefined
}
export interface ModalAtomTypeWithInnerState<T, K> {
  visible: boolean
  content: T | undefined
  innerState: K
}

export interface ModalAtomHook<T> {
  isModalVisible: boolean
  content: T | undefined
  showModal: (content: T) => void
  hideModal: () => void
}

export const useModalAtom = <T>(atom: RecoilState<ModalAtomType<T>>) => {
  const [modal, setModal] = useRecoilState(atom)
  const showModal = (content: T) => {
    setModal((x) => ({
      visible: true,
      content,
    }))
  }
  const hideModal = () => {
    setModal((x) => ({
      visible: false,
      content: x.content,
    }))
  }

  return {
    isModalVisible: modal.visible,
    content: modal.content,
    modal,
    showModal,
    hideModal,
  }
}

export const useModalAtomWithInnerState = <T, K>(
  atom: RecoilState<ModalAtomTypeWithInnerState<T, K>>,
  initialState: K,
) => {
  const [modal, setModal] = useRecoilState(atom)
  const showModal = (content: T) => {
    setModal((x) => ({
      visible: true,
      content,
      innerState: initialState,
    }))
  }
  const hideModal = () => {
    setModal((x) => ({
      ...x,
      visible: false,
    }))
  }
  const setInnerState = (innerState: K) => {
    setModal((x) => ({
      ...x,
      innerState,
    }))
  }

  return {
    isModalVisible: modal.visible,
    content: modal.content,
    modal,
    innerState: modal.innerState,
    showModal,
    hideModal,
    setInnerState,
  }
}
