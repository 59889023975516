import { Auth0Provider } from '@auth0/auth0-react'
import { ConfigProvider } from 'antd'
import ja_JP from 'antd/es/locale/ja_JP'
import { useEffect, useState } from 'react'

// hydrationを無効化する
const SafeHydrate = ({ children }: { children: JSX.Element }) => {
  const [visibility, setVisibility] = useState(false)
  useEffect(() => {
    setVisibility(true)
  }, [])

  if (!visibility) {
    return <></>
  }

  return <>{typeof window === 'undefined' ? null : children}</>
}

export const DefaultProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <SafeHydrate>
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN ?? ''}
        clientId={process.env.AUTH0_CLIENT_ID ?? ''}
        redirectUri={window.location.origin}
      >
        <ConfigProvider
          locale={ja_JP}
          theme={{
            token: {
              colorPrimary: `#376DF8`,
              borderRadius: 6,
              colorBgLayout: `#F9FBFD`,
              fontFamily: [
                `-apple-system`,
                'system-ui',
                'Segoe UI',
                `roboto`,
                'Helvetica Neue',
                `helvetica`,
                `arial`,
                `sans-serif`,
              ].join(', '),
              // colorBgBase: `#FDFEFF`,
              // 'table-body-sort-bg': '#FEFBEA', // bg-yellow-100
              // 'table-row-hover-bg': 'white',
              // // "table-padding-horizontal": "8px",
              // // "table-padding-vertical": "8px",
            },
          }}
        >
          {children}
        </ConfigProvider>
      </Auth0Provider>
    </SafeHydrate>
  )
}
