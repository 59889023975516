import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import type { CoreView } from '../../../schemas/View'
import type { ViewConfigField, ViewConfigSheet } from '../../../schemas/view_config'
import { userModel } from '../models/userModel'
import { compileResourceWithoutViews } from './util'

const config: ViewConfigSheet = {
  type: 'sheet',
  tree: {
    name: userModel.name,
    modelName: userModel.name,
    type: 'model',
  },
  fields: userModel.properties.map(
    (property): ViewConfigField => ({
      type: 'property',
      property: {
        nodeName: userModel.name,
        modelName: userModel.name,
        propertyName: property.name,
      },
      measures:
        property.name === 'name'
          ? [
              {
                type: 'preset',
                name: 'count',
                label: 'レコード数',
                function: 'count',
                fieldName: `${userModel.name}_age`,
              },
            ]
          : property.name === 'age'
            ? [
                {
                  type: 'preset',
                  name: 'sumAge',
                  label: '合計',
                  function: 'sum',
                  fieldName: `${userModel.name}_age`,
                },
              ]
            : [],
    }),
  ),
}

export function getUserSheetView() {
  const result = compileViewConfig(config, compileResourceWithoutViews)
  const userSheetView: CoreView = {
    id: 'userSheetView',
    name: 'userSheetView',
    config,
    queries: result!.queries,
    ui: result!.ui,
  }

  return userSheetView
}
