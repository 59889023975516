import { CopyOutlined } from '@ant-design/icons'
import { Button, message, Tooltip } from 'antd'
import { t } from 'i18next'

export const CopyButton = ({
  text,
  copyTargetDescription,
}: {
  text: string | undefined
  copyTargetDescription?: string
}) => {
  return (
    <Tooltip
      title={
        copyTargetDescription === undefined
          ? undefined
          : t(`{{copyTargetDescription}}をコピーします`, { copyTargetDescription })
      }
    >
      <Button
        icon={<CopyOutlined />}
        onClick={async () => {
          if (text === undefined || text === '') {
            return
          }
          await navigator.clipboard.writeText(text)
          void message.info(
            t(`{{description}}コピーしました`, {
              description: copyTargetDescription === undefined ? `` : `${copyTargetDescription}を`,
            }),
          )
        }}
        disabled={text === undefined || text === ''}
      />
    </Tooltip>
  )
}
