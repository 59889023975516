import { isNull, isSome } from '@salescore/buff-common'

import { getTailwindColorByIndex } from '../../../../constant/colors'
import type { CoreModel } from '../../../../schemas/model/model'
import type { ModelProperty } from '../../../../schemas/model/modelProperty'
import type { NodePath, ViewQueryField, ViewQueryNode } from '../../../../schemas/query'
import type { DataEntryCommon, ViewUIComponent, ViewUIDataEntry } from '../../../../schemas/ui/ui'
import type {
  NodePropertyName,
  ViewConfigForm,
  ViewConfigTreeNode,
  ViewConfigUiComponent,
} from '../../../../schemas/view_config'
import { POSTGRES_COLUMN_LENGTH_LIMIT } from '../../../query/executeViewQuery'
import { isRequiredProperty } from '../../../util/isRequiredProperty'
import { makeIdentifiable } from '../../../util/makeIdentifiable'
import type { CompileContext } from '../../compileViewConfig'
import { generateSelectOptionColor } from '../sheet/compileSheetViewConfigFields'

export interface FieldAndComponentNode {
  configComponent: ViewConfigUiComponent
  queryFields?: ViewQueryField[]
  component: ViewUIComponent
  children: FieldAndComponentNode[]
}

interface FormContext {
  context: CompileContext
  queryNodes: ViewQueryNode[]
  configNodes: ViewConfigTreeNode[]
}

// 雑に色を決定
let colorCount = 0

export function compileFormViewConfigComponents(
  components: ViewConfigForm['components'],
  context: FormContext,
): FieldAndComponentNode[] {
  if (components === undefined) {
    return []
  }
  colorCount = 0

  return components
    .map((component, index): FieldAndComponentNode | undefined => {
      return compile(component, context)
    })
    .compact()
}

function compile(component: ViewConfigUiComponent, context: FormContext): FieldAndComponentNode | undefined {
  if (component.componentType === undefined && component.property === undefined) {
    return undefined
  }

  if (component.property !== undefined) {
    return compileAsPropertyComponent(component, component.property, context)
  }
  return compileAsUiComponent(component, context)
}

function compileAsUiComponent(
  configComponent: ViewConfigUiComponent,
  context: FormContext,
): FieldAndComponentNode | undefined {
  return {
    configComponent,
    children: (configComponent.children ?? []).map((child) => compile(child, context)).compact(),
    ...getComponent(configComponent, context),
  }
}

function getComponent(
  configComponent: ViewConfigUiComponent,
  { queryNodes, configNodes, context }: FormContext,
): {
  component: ViewUIComponent
  queryField?: ViewQueryField[]
} {
  const type = configComponent.componentType
  switch (type) {
    case 'Row': {
      return {
        component: {
          type: 'Row',
          id: ``, // これ何に使うんだっけ？
          children: [], // childrenは後で合成
        },
      }
    }
    case 'Col': {
      return {
        component: {
          type: 'Col',
          id: ``, // これ何に使うんだっけ？
          span: 12,
          children: [],
        },
      }
    }
    case 'ChildRecordsForm': {
      const queryNode = queryNodes.find((x) => x.name === configComponent.nodeName)
      const configNode = configNodes.find((x) => x.name === configComponent.nodeName)
      const additionalFields = getAdditionalQueryField(queryNode, configNode, context)
      const model = context.modelSearcher.searchModel(configNode?.modelName)
      return {
        component: {
          type: 'ChildRecordsForm',
          label: queryNode?.meta.label ?? configComponent.nodeName!, // TODO
          tableNodeName: configComponent.nodeName!,
          nameFieldName: additionalFields?.nameQueryField?.name,
          recordUrlFieldName: additionalFields?.recordUrlQueryField?.name,
          children: [],
          // relation: configNode?.referenceToProperty?.modelName === configNode?.modelName ? 'one_to_many' : 'many_to_one'
          relation: queryNode?.read.join?.relation,
          color: model?.color ?? getTailwindColorByIndex((colorCount += 1)),
          icon: model?.icon,
        },
        queryField: [additionalFields?.nameQueryField, additionalFields?.recordUrlQueryField].compact(),
      }
    }
  }
  throw new Error(`[getComponent] not implemented. ${type ?? ''}`)
}

export function getAdditionalQueryField(
  queryNode: ViewQueryNode | undefined,
  configNode: ViewConfigTreeNode | undefined,
  context: CompileContext,
) {
  if (queryNode === undefined || configNode === undefined) {
    return
  }
  const model = context.modelSearcher.searchModel(configNode.modelName)
  if (model === undefined) {
    return
  }
  const nameProperty = model.properties.find((x) => x.meta === 'name')
  const recordUrlProperty = model.properties.find((x) => x.meta === 'record_url')
  const nameQueryField = getFieldFromProperty(nameProperty, model, queryNode, configNode, context)
  const recordUrlQueryField = getFieldFromProperty(recordUrlProperty, model, queryNode, configNode, context)
  return {
    nameQueryField,
    recordUrlQueryField,
  }
}

function getFieldFromProperty(
  property: ModelProperty | undefined,
  model: CoreModel,
  queryNode: ViewQueryNode,
  configNode: ViewConfigTreeNode,
  context: CompileContext,
) {
  if (property === undefined) {
    return
  }

  const name = makeIdentifiable([queryNode.name, property.name].join('_'), POSTGRES_COLUMN_LENGTH_LIMIT)
  const field: ViewQueryField = {
    name,
    nodePath: queryNode.path,
    read: {
      sql: `"${queryNode.name}"."${property.name}"`,
      ...getReferenceRelatedSql(configNode, queryNode, property, model.name, context),
      // ...component.override?.queryField?.read, // fieldのoverride
    },
    write:
      isNull(property.write) || (property.creatable === false && property.updatable === false)
        ? undefined
        : {
            streamName: configNode.modelName,
            propertySourceName: property.write.sourcePropertyName,
          },
    meta: {
      label: property.label,
      fieldType: property.type,
      fieldMetaType: property.meta ?? undefined,
      required: isRequiredProperty(property),
      defaultValue: undefined,
      searchable: property.meta === 'name',
      dependedPropertyNamesWithStreamName: [],
      // ...component.override?.queryField?.meta,
      // 以下、単純にoverrideさせない項目
      creatable:
        // (component.override?.queryField?.meta?.creatable ?? true) &&
        (property.creatable ?? true) && isSome(property.write),
      updatable:
        // (component.override?.queryField?.meta?.updatable ?? true) &&
        (property.updatable ?? true) && isSome(property.write),
    },
  }
  return field
}

function compileAsPropertyComponent(
  component: ViewConfigUiComponent,
  nodeProperty: NodePropertyName,
  formContext: FormContext,
): FieldAndComponentNode | undefined {
  const { queryNodes, configNodes, context } = formContext
  const queryNode = queryNodes.find((node) => node.name === nodeProperty.nodeName)
  const configNode = configNodes.find((node) => node.name === nodeProperty.nodeName)
  const model = context.modelSearcher.searchModel(nodeProperty.modelName)
  const property = context.modelSearcher.searchProperty(nodeProperty.modelName, nodeProperty.propertyName)

  if (queryNode === undefined || configNode === undefined) {
    return undefined // TODO
  }
  if (property === undefined || model === undefined) {
    return undefined // TODO
  }

  const name = makeIdentifiable([queryNode.name, property.name].join('_'), POSTGRES_COLUMN_LENGTH_LIMIT)
  const queryField: ViewQueryField = {
    name,
    nodePath: queryNode.path,
    read: {
      sql: `"${queryNode.name}"."${property.name}"`, // TODO: このレイヤーでSQL変換したくない
      ...getReferenceRelatedSql(configNode, queryNode, property, model.name, formContext.context),
      // ...component.override?.queryField?.read, // fieldのoverride
    },
    write:
      isNull(property.write) || (property.creatable === false && property.updatable === false)
        ? undefined
        : {
            streamName: nodeProperty.modelName,
            propertySourceName: property.write.sourcePropertyName,
          },
    meta: {
      label: property.label,
      fieldType: property.type,
      fieldMetaType: property.meta ?? undefined,
      required: isRequiredProperty(property),
      defaultValue: undefined,
      searchable: property.meta === 'name',
      // TODO
      // conditionalEffects: isSome(component.conditionalEffects)
      //   ? component.override!.conditionalEffects.map((x) => compileConditionalEffects(x.expression, x.effect)).compact()
      //   : undefined,
      dependedPropertyNamesWithStreamName: [
        {
          streamName: nodeProperty.modelName,
          propertyName: nodeProperty.propertyName,
        },
      ],
      ...component.override?.queryField?.meta,
      // 以下、単純にoverrideさせない項目
      creatable:
        // (component.override?.queryField?.meta?.creatable ?? true) &&
        (property.creatable ?? true) && isSome(property.write),
      updatable:
        // (component.override?.queryField?.meta?.updatable ?? true) &&
        (property.updatable ?? true) && isSome(property.write),
    },
  }

  const common: DataEntryCommon = {
    fieldName: queryField.name,
    readonly: queryField.write === undefined,
    required: isRequiredProperty(property),
  }
  return {
    configComponent: component,
    queryFields: [queryField],
    component: {
      ...common,
      type: 'FormItem',
      label: property.label, // TODO: override
      child: getDataEntryFromField(queryField, property, common),
    },
    children: (component.children ?? []).map((child) => compile(child, formContext)).compact(),
  }
}

function getReferenceRelatedSql(
  configNode: ViewConfigTreeNode,
  queryNode: ViewQueryNode,
  property: ModelProperty,
  propertyModelName: string,
  context: CompileContext,
): { labelSql: string; searchSql: string; labelNodePath: NodePath } | undefined {
  // TODO: polymorphicのときにどうすべきか、現状解がない
  const xs = (property.referenceTo ?? []).map((reference) => {
    const childConfigNode = (configNode.children ?? []).find((childNode) => {
      return (
        childNode.referenceToProperty?.propertyName === property.name &&
        childNode.referenceToProperty.modelName === propertyModelName &&
        childNode.referenceToProperty.referenceTo.modelName === reference.modelName
      )
    })
    const childQueryNode = (queryNode.children ?? []).find((x) => x.name === childConfigNode?.name)
    if (childQueryNode === undefined || childConfigNode === undefined) {
      return
    }

    const childModel = context.modelSearcher.searchModel(childConfigNode.modelName)
    const nameProperty = childModel?.properties.find((x) => x.meta === 'name')
    return {
      labelSql: `"${childQueryNode.name}"."${nameProperty?.name ?? 'id'}"`, // TODO: ここでSQLを組み立てない
      labelNodePath: childQueryNode.path,
      searchSql: childQueryNode.read.join!.search.sql,
    }
  })
  return xs.compact().first()
}

function getDataEntryFromField(
  field: ViewQueryField,
  property: ModelProperty,
  common: DataEntryCommon,
): ViewUIDataEntry {
  if (field.read.searchSql !== undefined && field.read.labelSql !== undefined) {
    return {
      type: 'SearchSelect',
      fieldName: field.name,
      labelFieldName: `${field.name}_label`,
      searchSql: field.read.searchSql,
    }
  }
  if ((property.selectOptions ?? []).length > 0) {
    return {
      ...common,
      type: 'Select',
      options: (property.selectOptions ?? []).map((x, index) => ({
        ...x,
        color: x.color ?? generateSelectOptionColor(index),
      })),
      multiple: property.meta === 'multi_select',
    }
  }
  switch (field.meta.fieldMetaType) {
    case 'text': {
      return {
        type: 'Input',
        textarea: true,
        ...common,
      }
    }
    case 'html': {
      return {
        type: 'Input',
        textarea: true,
        ...common,
      }
    }
  }
  switch (field.meta.fieldType) {
    case 'string': {
      return {
        type: 'Input',
        ...common,
      }
    }
    case 'numeric': {
      return {
        type: 'InputNumber',
        ...common,
      }
    }
    case 'boolean': {
      return {
        type: 'Checkbox',
        ...common,
      }
    }
    case 'date': {
      return {
        type: 'DatePicker',
        ...common,
      }
    }
    case 'datetime': {
      return {
        type: 'DatePicker',
        showTime: true,
        // minuteStep: 5,
        ...common,
      }
    }
  }
  return {
    type: 'Input',
    ...common,
  }
}
