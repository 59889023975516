import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import type { CoreView } from '../../../schemas/View'
import type { ViewConfigKpiPivot } from '../../../schemas/view_config'
import { getCompanySheetView } from './companySheetView'
import { getOpportunitySheetView } from './opporunitySheetView'
import { getShoudanKpiView } from './shoudanKpiView'
import { getShoudanSakuseiKpiView } from './shoudanSakuseiKpiView'
import { getUserSheetView } from './userSheetView'
import { compileResourceWithoutViews } from './util'

export function getKpiPivotView(): CoreView {
  const companySheetView = getCompanySheetView()
  const opportunitySheetView = getOpportunitySheetView()
  const shoudanSakuseiKpiView = getShoudanSakuseiKpiView()
  const config: ViewConfigKpiPivot = {
    type: 'kpiPivot',
    kpis: [
      {
        viewId: getShoudanKpiView().id,
      },
      {
        viewId: shoudanSakuseiKpiView.id,
      },
      // {
      //   id: '従業員数',
      //   measures: [
      //     {
      //       measureWithDimension: {
      //         viewId: companySheetView.id,
      //         measureName: 'sumEmployees', // TODO
      //       },
      //       measureRole: 'actual',
      //     },
      //   ],
      // },
      // {
      //   id: 'ユーザー数',
      //   measures: [
      //     {
      //       measureWithDimension: {
      //         viewId: userSheetView.id,
      //         measureName: 'count', // TODO
      //         dimensionAliases: [
      //           {
      //             filedName: `users_category`,
      //             alias: `companies_category`,
      //           },
      //           {
      //             filedName: `users_name`,
      //             alias: `companies_name`,
      //           },
      //           {
      //             filedName: `users_status`,
      //             alias: `companies_status`,
      //           },
      //         ],
      //       },
      //       measureRole: 'actual',
      //     },
      //   ],
      // },
    ],
    // TODO: additionalConfigに移行
    // parameter: {
    //   pivot: {
    //     rows: [
    //       CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION,
    //       // {
    //       //   fieldName: `opportunities_phase`,
    //       //   label: 'フェイズ',
    //       // },
    //     ], // TODO
    //     columns: [
    //       // {
    //       //   fieldName: `companies_status`,
    //       //   label: 'ステータス',
    //       // },
    //       CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION,
    //     ],
    //   },
    // },
    presets: [],
  }

  const sheetViews = [companySheetView, getUserSheetView(), opportunitySheetView]
  const views = [getShoudanKpiView(), shoudanSakuseiKpiView, ...sheetViews]
  const result = compileViewConfig(config, { ...compileResourceWithoutViews, views })

  return {
    id: 'companyKpiPivotSheetView',
    name: 'companyKpiPivotSheetView',
    config,
    queries: result!.queries,
    ui: result!.ui,
  }
}
