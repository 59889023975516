import { type PgEmulator, range } from '@salescore/buff-common'
import dayjs from 'dayjs'

import type { CoreModel } from '../../../schemas/model/model'
import type { ModelProperty } from '../../../schemas/model/modelProperty'
import { companyModel } from './companyModel'
import { contactModel, contactRecords } from './contactModel'
import { userModel, userRecords } from './userModel'

const phases = [`受注`, `決済者価値合意`, `案件化`, `アポイントメント`, `失注`]

export const opportunityModel: CoreModel = {
  name: 'opportunities',
  label: '案件',
  properties: [
    {
      name: 'name',
      label: '名前',
      type: 'string' as const,
      meta: 'name' as const,
    },
    {
      name: 'company_id',
      label: '企業ID',
      type: 'string' as const,
      referenceTo: [
        {
          modelName: companyModel.name,
        },
      ],
    },
    {
      name: 'contact_id',
      label: '担当者ID',
      type: 'string' as const,
      referenceTo: [
        {
          modelName: contactModel.name,
        },
      ],
    },
    {
      name: 'created_by',
      label: '作成者',
      type: 'string' as const,
      referenceTo: [
        {
          modelName: userModel.name,
        },
      ],
    },
    {
      name: 'assigned_to',
      label: '担当者',
      type: 'string' as const,
      referenceTo: [
        {
          modelName: userModel.name,
        },
      ],
    },
    {
      name: 'phase',
      label: 'フェイズ',
      type: 'string' as const,
      selectOptions: phases.map((value) => ({
        value,
        label: value,
      })),
    },
    {
      name: 'sales',
      label: '売上',
      type: 'numeric' as const,
    },
    {
      name: 'created_at',
      label: '作成日',
      type: 'date' as const,
    },
    {
      name: 'closed_on',
      label: '受注日',
      type: 'date' as const,
    },
  ].map(
    (x): ModelProperty => ({
      ...x,
      write: {
        sourcePropertyName: x.name,
      },
      validations:
        x.name === 'name'
          ? [
              {
                function: {
                  type: 'func',
                  name: 'present',
                  args: [], // TODO: 記法忘れた
                },
                message: ``,
                feedbackProperties: [],
              },
            ]
          : [],
    }),
  ),
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type OpportunityRecord = {
  id: string
  name: string
  company_id: string | null
  contact_id: string | null
  created_by: string | null
  assigned_to: string | null
  phase: string
  sales: number
  created_at: Date
  closed_on: Date
}

export const opportunityRecords: OpportunityRecord[] = range(0, 20).map((index) => ({
  id: `opportunityId${index}`,
  name: `name${index}`,
  // company_id: companyRecords[i % 4]?.id ?? null,
  company_id: contactRecords[index % 4]?.company_id ?? null,
  contact_id: contactRecords[index % 4]?.id ?? null,
  created_by: userRecords[index % 4]?.id ?? null,
  assigned_to: userRecords[index % 3]?.id ?? null,
  phase: phases[index % phases.length]!,
  sales: index,
  created_at: dayjs().startOf('month').add(index, 'days').toDate(),
  closed_on: dayjs().startOf('month').add(1, 'month').add(index, 'days').toDate(),
}))

export function initializeOpportunityModelTable(database: PgEmulator): void {
  database.initializeTable<OpportunityRecord>({
    name: opportunityModel.name,
    records: opportunityRecords,
  })
}
