import { type PgEmulator, range } from '@salescore/buff-common'
import type { CoreModel, ModelProperty } from '@salescore/core'

import { userModel, userRecords } from './userModel'

export const companyModel: CoreModel = {
  name: 'companies',
  label: '会社',
  icon: 'faBuilding',
  color: '#10b981',
  properties: [
    {
      name: 'name',
      label: '名前',
      type: 'string' as const,
      meta: 'name' as const,
      write: {
        sourcePropertyName: `name`,
      },
    },
    {
      name: '_url',
      label: 'レコードURL',
      type: 'string' as const,
      meta: 'record_url' as const,
    },
    {
      name: 'created_by',
      label: '作成者',
      type: 'string' as const,
      referenceTo: [
        {
          modelName: userModel.name,
        },
      ],
    },
    {
      name: 'created_at',
      label: '作成日時',
      type: 'datetime' as const,
      write: {
        sourcePropertyName: `created_at`,
      },
    },
    {
      name: 'category',
      label: 'カテゴリ',
      type: 'string' as const,
      selectOptions: range(0, 3).map((index) => ({
        value: `category${index}`,
        label: `category${index}`,
      })),
    },
    {
      name: 'status',
      label: 'ステータス',
      type: 'string' as const,
      meta: `multi_select` as const,
      selectOptions: range(0, 2).map((index) => ({
        value: `status${index}`,
        label: `status${index}`,
      })),
    },
    {
      name: 'employees',
      label: '従業員数',
      type: 'numeric' as const,
    },
  ].map(
    (x): ModelProperty => ({
      ...x,
      write: {
        sourcePropertyName: x.name,
      },
      validations:
        x.name === 'name'
          ? [
              {
                function: {
                  type: 'func',
                  name: 'present',
                  args: [], // TODO: 記法忘れた
                },
                message: ``,
                feedbackProperties: [],
              },
            ]
          : [],
    }),
  ),
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type CompanyRecord = {
  id: string
  name: string
  created_by: string
  category: string
  status: string
  employees: number
}
export const companyRecords: CompanyRecord[] = range(0, 9).map((index) => ({
  id: `companyId${index}`,
  name: `株式会社Buff-${index}`,
  _url: `https://example.com/?id=${index}`,
  created_by: userRecords[index % 3]!.id,
  category: `category${index % 3}`,
  status: `status${index % 2}`,
  employees: index,
}))

export function initializeCompanyModelTable(database: PgEmulator) {
  database.initializeTable<CompanyRecord>({
    name: companyModel.name,
    records: companyRecords,
  })
}
