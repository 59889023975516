import { wrapKeyboardEvent } from '@salescore/frontend-common'
import { type KeyboardEvent, useEffect } from 'react'

import { useLauncherState } from './hooks/useLauncherState'
import { LauncherBodyV2 } from './LauncherBodyV2'
import { SuspenseWithLoading } from './SuspenseWithLoading'

export const GlobalLauncher = (): JSX.Element => {
  const launcherState = useLauncherState()
  const { visibility, open, hide } = launcherState.launcher

  useEffect(() => {
    const handler = (event: unknown): void => {
      const keyboardEvent = event as KeyboardEvent<HTMLDivElement> // TODO
      const wrapped = wrapKeyboardEvent(keyboardEvent)
      if (wrapped.key === 'k' && wrapped.commandKey && !visibility) {
        open()
        keyboardEvent.preventDefault()
      }
      if (wrapped.code === 'Escape') {
        hide()
        keyboardEvent.preventDefault()
      }
    }
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  })

  // エラー表示などを出したくないのでhandleQueryは使わない
  if (!visibility) {
    return <></>
  }

  return (
    <SuspenseWithLoading>
      <LauncherBodyV2 launcherState={launcherState} />
    </SuspenseWithLoading>
  )
}
