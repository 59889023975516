import { type PgEmulator, range } from '@salescore/buff-common'

import type { CoreModel } from '../../../schemas/model/model'
import { userRecords } from './userModel'

export const salescoreUserModel: CoreModel = {
  name: 'salescore_users',
  label: 'ユーザー',
  properties: [
    {
      name: 'name',
      label: '名前',
      type: 'string',
      meta: 'name',
    },
    {
      name: 'email',
      label: 'メールアドレス',
      type: 'string',
    },
    {
      name: 'salesforce_user_id',
      label: 'SalesforceのユーザーID',
      type: 'string',
    },
  ],
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type SalescoreUserRecord = {
  id: string
  name: string
  email: string
  salesforce_user_id: string | null
  image_url: string | null
}
const imageUrls = [
  `https://ucarecdn.com/55c4b5da-39a8-4350-b195-4b63eb071529/`,
  `https://ucarecdn.com/b3080cd3-0d48-43b1-8653-bd3e5233fcb6/`,
  `https://ucarecdn.com/948552e5-e9f0-4e21-9ef5-dc3a79a7d645/`,
  `https://ucarecdn.com/79a5a5e6-727b-48b5-8d5b-d568ed767280/`,
]
const names = [`山田 花子`, `佐藤 一郎`, `田中 良子`, `鈴木 太郎`]
export const salescoreUserRecords: SalescoreUserRecord[] = range(0, 4).map((index) => ({
  id: `salescoreUserId${index}`,
  name: names[index] ?? `name${index}`,
  email: `email${index % 3}@example.com`,
  salesforce_user_id: userRecords[index]?.id ?? null,
  image_url: imageUrls[index] ?? null,
}))

export function initializeSalescoreUserModelTable(database: PgEmulator) {
  database.initializeTable<SalescoreUserRecord>({
    name: salescoreUserModel.name,
    records: salescoreUserRecords,
  })
}
