import { type PgEmulator, range } from '@salescore/buff-common'

import type { CoreModel } from '../../../schemas/model/model'

export const userModel: CoreModel = {
  name: 'users',
  label: 'ユーザー',
  properties: [
    {
      name: 'name',
      label: '名前',
      type: 'string',
      meta: 'name',
    },
    {
      name: 'category',
      label: 'カテゴリ',
      type: 'string',
      selectOptions: range(0, 3).map((index) => ({
        value: `category${index}`,
        label: `category${index}`,
      })),
    },
    {
      name: 'status',
      label: 'ステータス',
      type: 'string',
      selectOptions: range(0, 2).map((index) => ({
        value: `status${index}`,
        label: `status${index}`,
      })),
    },
    {
      name: 'age',
      label: '年齢',
      type: 'numeric',
    },
  ],
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type UserRecord = {
  id: string
  name: string
  category: string
  status: string
  age: number
}
export const userRecords: UserRecord[] = range(0, 4).map((index) => ({
  id: `userId${index}`,
  name: `ユーザー${index}`,
  category: `category${index % 3}`,
  status: `status${index % 2}`,
  age: index,
}))

export function initializeUserModelTable(database: PgEmulator) {
  database.initializeTable<UserRecord>({
    name: userModel.name,
    records: userRecords,
  })
}
