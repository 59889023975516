//
// salesforceのhistoryオブジェクトをもとに、N日前までのsnapshotを作成する
//

import { camelToSnake, castBoolean, r } from '@salescore/buff-common'
import dayjs from 'dayjs'
import { Logger } from 'tslog'

import type { SnapshotConfig, ViewQueryResultSnapshot } from '../../../schemas/query'
import type { SqlClient } from '../executeViewQueryForAggregationQuery'

/**
 * @deprecated core内でlogが必要になるという設計を見直す
 */
const simpleLogger = new Logger({
  type: 'json',
})

interface History {
  created_date: string
  field: string
  old_value: string | null
  new_value: string | null
  // NOTE: Salesforceのhistoryオブジェクトでは、カスタムオブジェクトではparent_idだが、標準オブジェクトではparent_idという名前ではないので注意
  //       configをcompileするときに、名前のマッピングをしている
  parent_id: string
}

type SaelsforceRecord = Record<string, unknown> & { id: string }

interface Snapshot {
  dayBefore: number
  record: SaelsforceRecord
}

export async function generateSnapshotsFromHistory({
  snapshotsConfigs,
  records,
  sqlClient,
}: {
  snapshotsConfigs: SnapshotConfig[]
  records: Array<Record<string, unknown>> // 実行結果そのまま
  sqlClient: SqlClient
}): Promise<ViewQueryResultSnapshot> {
  try {
    const configByNodeName = snapshotsConfigs.groupBy((x) => x.nodeName).toArray()
    // ノードごとに処理。
    const results: ViewQueryResultSnapshot = {}
    for (const [nodeName, configs] of configByNodeName) {
      const recordIds = records
        .map((record) => record[`${nodeName}_id`])
        .unique()
        .compact() as string[] // TODO: idの生成方法に依存している
      if (recordIds.length > 3000) {
        continue
      }
      const modelName = configs.first()!.modelName // TODO: modelNameは必ず単一である前提でロジックを組んでいる
      const propertyNames = configs.map((x) => x.propertyName)
      const maxDay = configs.map((x) => x.day).max()!
      const parentIdColumnName = configs.first()!.historyModelParentIdColumnName
      const historyModelName = configs.first()!.historyModelName

      // SQLを組み立てて実行
      const currentRecordSql = /* sql */ `SELECT
  id, ${propertyNames.join(', ')}
  FROM ${modelName}
  WHERE id in (${recordIds.map((id) => `'${id}'`).join(', ')})`
      const historySql = `SELECT 
  ${parentIdColumnName} as parent_id, created_date, field, old_value, new_value 
  FROM ${historyModelName}
  WHERE created_date > '${dayjs().subtract(maxDay, 'day').format()}'
  AND ${parentIdColumnName} in (${recordIds.map((id) => `'${id}'`).join(', ')})
  AND field in (${configs.map((x) => `'${x.fieldName}'`).join(', ')})`
      const currentRecords = await sqlClient.query(currentRecordSql)
      const histories = await sqlClient.query(historySql)

      const historiesMapper = (histories.rows as unknown as History[]).groupBy((x) => x.parent_id).data
      const result = (currentRecords.rows as SaelsforceRecord[]).map((currentRecord) => {
        const hs = historiesMapper[currentRecord.id] ?? []
        return {
          recordId: currentRecord.id,
          snapshots: generate(currentRecord, hs, configs.map((x) => x.day).unique()),
        }
      })

      // ここまでは型の分かりやすさを重視して配列ベースで進めてきたが、
      // client側でのパフォーマンス軽減のため、key-value形式に変換して返す
      // { [nodeName]: { [recordId]: Snapshot[] } }} // の形
      const grouped = r(result.groupByUniqueKey((x) => x.recordId)).transformValues((x) => {
        return r(x.snapshots.groupByUniqueKey((x) => `day${x.dayBefore}`)).transformValues((x) => x.record).data
      }).data
      results[nodeName] = grouped
    }

    return results
  } catch (error) {
    // snapshotでエラーが出ていてもエラーにしない

    simpleLogger.error(error)
    return {}
  }
}

//
// historyをsnapshotに変換する。
// ・history: 項目ごとの変更履歴。例: { date: '2023-08-01', fieldName: 'stageName', after: '失注' }
// ・snapshot: 「N日前のレコードの状態」を保持するオブジェクト。例: { dayBefore: 10, record: { stageName: '失注', nextDate: '2023-01-01' } }
// historyのままだと後続のロジックがやりづらいので、ここでsnapshotに変換する必要がある
//
function generate(current: SaelsforceRecord, histories: History[], days: number[]): Snapshot[] {
  const day0 = {
    dayBefore: 0, // 現在の値
    record: current,
  }
  const snapshots: Snapshot[] = [day0]

  // historyをsnapshotに変換する前に、dayBeforeを求める下処理を行っておく
  const historiesWithDayNumber = histories
    .map((x) => {
      const date = dayjs(x.created_date)
      return {
        ...x,
        // date,
        unixtime: date.unix(), // ソートのために使う
        dateString: date.format('YYYY-MM-DD'),
        dayBefore: dayjs().diff(date, 'day'), // 0, 1, 2, ... となるはず
      }
    })
    .sortBy((x) => x.unixtime)
    .reverse() // 新しい順とする

  // 現在の値をベースに、historyを逐次的に適用し、historyからsnapshotを再現する
  // 同じ日に複数回更新があった場合は、その日の最後の値がsnapshot上の値となる
  const record = { ...current }
  for (const history of historiesWithDayNumber) {
    // 日付が切り替わったときは、snapshotに追加
    if (history.dayBefore !== snapshots.last()!.dayBefore) {
      snapshots.push({
        dayBefore: history.dayBefore,
        record: { ...record },
      })
    }
    // historyのデータでrecordを更新
    // history.fieldはSalesforceのフィールド名そのままなのでcamelCaseだが、recordはSALESCOREのテーブルなのでsnake_caseに変換が必要
    record[camelToSnake(history.field)] = history.old_value
  }
  // 現在のレコードの状態は、すべてのhistoryを適用し終わった値
  // すなわち、最古のhistoryの1日前の値になっているので、これも格納する
  snapshots.push({
    dayBefore: snapshots.last()!.dayBefore + 1,
    record: { ...record },
  })

  // ここまででhistoryをベースにしたsnapshotsができているが、
  // daysで指定されている日付がhistoryに存在しないことがある
  // daysで指定されている日付に修正してから返す
  // （例）2日前に「フェイズ」を変更したとき、historyには2日前の履歴レコードがある
  //      よって、ここまでの実装だと、dayが0,-2のsnapshotが生成されている
  //      ハイライト条件で「7日前のフェイズと現在のフェイズが異なる」を指定したときに必要になるのは、dayが0,-7のsnapshotである
  //      -7のときのレコードの状態は、（他に変更履歴がない限り）-2のときと同じなので、-2を-7にして返す
  // （詳細）
  // 「7日前」のとき、daysの値は7が入っている
  // snapshotsが0,2,8だったとき、後方から探してsnapshots < dayになる最初の値を見つければ良い
  // daysが大きい時は、最後のsnapshotが選ばれるので問題ない
  // daysが0以下のとき、すなわち未来の値は入らないはずだが、一応0日目=現在の値をベースとする
  const sortedSnapshots = snapshots.sortBy((x) => x.dayBefore).reverse()
  const sortedDays = [0, ...days].unique().sortBy((x) => x)
  const fixedSnapshots = sortedDays.map((dayBefore): Snapshot => {
    const snapshot = sortedSnapshots.find((snapshot) => snapshot.dayBefore <= dayBefore) ?? snapshots.last()!
    return {
      ...snapshot,
      dayBefore,
    }
  })

  //
  // historyに格納されている値は全て文字列型にキャストされてしまっているので、元の型に再キャストしたい
  // currentの値を元に、スナップショットを再キャストする
  //
  const keyToCastFunctionMapper = r(current).transformValues((value) => {
    switch (typeof value) {
      case 'number': {
        return (x: unknown) => (typeof x === 'string' ? Number(x) : x)
      }
      case 'boolean': {
        return (x: unknown) => (typeof x === 'string' ? castBoolean(x) : x)
      }
      default: {
        return (x: unknown) => x
      }
    }
  }).data

  return fixedSnapshots.map((snapshot) => {
    return {
      ...snapshot,
      record: {
        ...r(snapshot.record).transformValues((value, key) => keyToCastFunctionMapper[key]?.(value) ?? value).data,
        id: snapshot.record.id, // 型の都合で必要
      },
    }
  })
}

export function generateSalesforceHistoryModelName(modelName: string) {
  if (modelName === `salesforce_opportunity`) {
    // 商談オブジェクトのみ命名規則が違う
    // （より正確には、OpportunityHistoryオブジェクトという他と違うスキーマのオブジェクトが存在する）
    return `salesforce_opportunity_field_history`
  }
  return `${modelName.replace(/__c$/, '__')}_history` // XXX: sfの命名規則に依存している
}
