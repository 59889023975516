import { Tooltip, type TooltipProps } from 'antd'
import { useState } from 'react'

const environmentColor = (): TooltipProps['color'] => {
  switch (process.env.APP_ENV) {
    case 'stg': {
      return 'blue'
    }
    case 'dev': {
      return 'red'
    }
    default: {
      return 'purple'
    }
  }
}
/**
 * 気の効いたカスタマイズがあったらやって下さい
 */
export const EnvTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(true)
  return process.env.APP_ENV === 'prod' ? (
    <></>
  ) : (
    <Tooltip
      title={`APP_ENV=${process.env.APP_ENV}`}
      open={showTooltip}
      placement={'left'}
      color={environmentColor()}
      onOpenChange={setShowTooltip}
    />
  )
}
