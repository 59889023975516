// import { NotImplementedError } from '../../../common/errors'
// import { DEFAULT_TIMEZONE_INTERVAL, Options, Query } from '../../Query'

import type { DateTimeFilterExpressionType } from '../../../../../schemas/misc/timeframe'
import type { FieldType } from '../../../../../schemas/query'
import type { AlwaysFilter, FilterType } from '../../../../../schemas/query/filterTypes'
import { NotImplementedError, ValidationError } from '../../../../errors'
import type { CompileContext } from '../../../compileViewConfig'
import { generateArrayFilter } from './generateFilterSql/generateArrayFilter'
import { generateBooleanFilter } from './generateFilterSql/generateBooleanFilter'
import { generateDateFilter } from './generateFilterSql/generateDateFilter'
import { generateNumericFilter } from './generateFilterSql/generateNumericFilter'
import { generateStringFilter } from './generateFilterSql/generateStringFilter'
import { generateTimeFilter } from './generateFilterSql/generateTimeFilter'

export const generateFilterSql = ({
  fieldSql,
  fieldType,
  filterType,
  filterValue,
  dateTimeType,
  context,
  shouldConsiderCase,
}: {
  fieldSql: string
  fieldType: FieldType
  filterType: FilterType
  filterValue: unknown
  dateTimeType: DateTimeFilterExpressionType | undefined
  context: CompileContext
  // 現状propertyFilterのみで使用
  shouldConsiderCase?: boolean
}): string | undefined => {
  // TODO: いったんalwaysFilterに揃えている
  const filter: AlwaysFilter = {
    field_name: fieldSql,
    field_type: fieldType,
    filter_type: filterType,
    filter_expression: filterValue as string | number | undefined,
  }

  try {
    switch (fieldType) {
      case 'string': {
        return generateStringFilter(filter, shouldConsiderCase)
      }
      case 'integer': {
        return generateNumericFilter(filter)
      }
      case 'numeric': {
        return generateNumericFilter(filter)
      }
      case 'date': {
        return generateDateFilter(filter, undefined, dateTimeType, context)
      }
      case 'datetime': {
        return generateDateFilter(filter, undefined, dateTimeType, context)
      }
      case 'time': {
        return generateTimeFilter(filter)
      }
      case 'boolean': {
        return generateBooleanFilter(filter)
      }
      case 'array': {
        return generateArrayFilter(filter)
      }
      case 'object': {
        throw new NotImplementedError(`not implemented filter: ${filter.field_type}`)
      }
      default: {
        // const x: never = filter.field_type // TODO
        return generateBooleanFilter(filter)
      }
    }
  } catch (error: unknown) {
    if (error instanceof ValidationError) {
      // notifyBugsnag({ error: e })

      return undefined
    }
    throw error
  }
}
