import { extendProperties } from '@salescore/buff-common'

import type { DimensionGroup } from '../../schemas/misc/dimension_group'
import type { CoreModel } from '../../schemas/model/model'
import type { ViewQuery } from '../../schemas/query'
import type { ViewUI } from '../../schemas/ui/ui'
import type { CoreView } from '../../schemas/View'
import type { ViewConfig, ViewConfigAdditionalConfig } from '../../schemas/view_config'
import { compileFormViewConfig } from './compileViewConfig/form/compileFormViewConfig'
import { compileKpiViewConfig } from './compileViewConfig/kpi/compileKpiViewConfig'
import { compileKpiPivotViewConfig } from './compileViewConfig/kpiPivot/compileKpiPivotViewConfig'
import { ModelSearcher } from './compileViewConfig/ModelSearcher'
import { compileKpiTimeSeriesViewConfig } from './compileViewConfig/ri/compileKpiTimeSeriesViewConfig'
import type { CompileSheetAndMeasureResultExtra } from './compileViewConfig/sheet/compileSheetAndMeasure'
import { compileSheetViewConfig } from './compileViewConfig/sheet/compileSheetViewConfig'
import { ViewSearcher } from './compileViewConfig/ViewSearcher'

// TODO: テスト側の都合
extendProperties()

export class CompileLog {
  private readonly errors: string[]
  private readonly warns: string[]
  private readonly logs: string[]

  public constructor() {
    this.errors = []
    this.warns = []
    this.logs = []
  }

  public warn(x: string) {
    this.warns.push(x)
  }

  public log(x: string) {
    this.logs.push(x)
  }

  public showWarns() {
    return this.warns
  }

  public error(x: string) {
    this.errors.push(x)
  }

  public showErrors() {
    return this.errors
  }
}

export type CompileViewConfigResponse =
  | {
      queries: ViewQuery[]
      query: ViewQuery // deprecated
      ui: ViewUI
      context: CompileContext
      // 自動インデックス付与のため、どんなdimensionを使ったのかを知りたい。kpiPivotで使うことを想定してarrayになってるのがかなり微妙
      extra?: CompileSheetAndMeasureResultExtra[]
    }
  | undefined

export interface OrganizaitonSetting {
  userGroupCategoryNames: string[]
  dimensionGroups: DimensionGroup[]
  accountClosingMonth: number
}

export interface CompileResources {
  view: Pick<CoreView, 'id' | 'name'> // 本来はconfigだけで完結させ、viewの情報そのものは不要な想定だったが、kpi周りで必要になった
  models: CoreModel[]
  views: CoreView[]
  organizationSetting: OrganizaitonSetting
}
export interface CompileContext {
  modelSearcher: ModelSearcher
  viewSearcher: ViewSearcher
  additionalConfig: ViewConfigAdditionalConfig | undefined
  logs: CompileLog
  resources: CompileResources
}

export function compileViewConfig(
  config: ViewConfig,
  resources: CompileResources,
  context?: {
    additionalConfig?: ViewConfigAdditionalConfig
  },
): CompileViewConfigResponse {
  // TODO: 2022/11/11 どこかのタイミングでviewがundefinedになりうるようなので……。
  if (resources.view === undefined) {
    return undefined
  }

  const modelSearcher = new ModelSearcher(resources.models, resources.organizationSetting.userGroupCategoryNames)
  const viewSearcher = new ViewSearcher(resources.views)
  const type = config.type
  const logs = new CompileLog()
  const compileContext: CompileContext = {
    resources,
    additionalConfig: context?.additionalConfig,
    modelSearcher,
    viewSearcher,
    logs,
  }

  switch (type) {
    case 'sheet': {
      return compileSheetViewConfig(config, compileContext)
    }
    case 'form': {
      return compileFormViewConfig(config, compileContext)
    }
    case 'kpi': {
      return compileKpiViewConfig(config, compileContext)
    }
    case 'kpiPivot': {
      return compileKpiPivotViewConfig(config, compileContext)
    }
    case 'kpiTimeSeries': {
      return compileKpiTimeSeriesViewConfig(config, compileContext)
    }
    default: {
      const x: never = type
      // throw new Error(`not implemented`)

      return compileSheetViewConfig(config, compileContext)
    }
  }
}
