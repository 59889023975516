import type { DateTimeFilterExpressionType } from '../../../../../../schemas/misc/timeframe'
import { type AlwaysFilter, dateFilterTypes, type TimezoneInterval } from '../../../../../../schemas/query/filterTypes'
import { NotImplementedError, ValidationError } from '../../../../../errors'
import type { CompileContext } from '../../../../compileViewConfig'
import { dateCompareFilter, nullFilter } from './filters'
import { validateFilterType } from './validateFilterType'

// https://docs.looker.com/reference/filter-expressions
export const generateDateFilter = (
  filter: AlwaysFilter,
  interval: TimezoneInterval | undefined,
  dateTimeTypeRaw: DateTimeFilterExpressionType | undefined,
  context: CompileContext,
): string | undefined => {
  const dateTimeType: DateTimeFilterExpressionType = dateTimeTypeRaw ?? 'datetime'
  const type = validateFilterType(filter.filter_type, dateFilterTypes)

  // 旧ロジックでfilter_expressionにnullが入ってしまうケースの扱いが微妙だった。
  // 現状は不要なはずだが、一応追加している
  if (filter.filter_expression === 'null') {
    switch (type) {
      case 'equal': {
        return nullFilter(filter, false)
      }
      case 'not_equal': {
        return nullFilter(filter, true)
      }
      default: {
        throw new ValidationError(`nullと日付を比較することはできません`)
      }
    }
  }

  switch (type) {
    case 'null': {
      return nullFilter(filter, false)
    }
    case 'not_null': {
      return nullFilter(filter, true)
    }
  }

  if (dateTimeType === undefined) {
    throw new ValidationError(`dateTimeType is not defined`)
  }

  switch (type) {
    // case 'in':
    //   return stringInFilter(filter, false)
    // case 'not_in':
    //   return stringInFilter(filter, true)
    case 'equal': {
      return dateCompareFilter(filter, interval, dateTimeType, context)
    }
    case 'not_equal': {
      return dateCompareFilter(filter, interval, dateTimeType, context)
    }
    case 'greater_than': {
      return dateCompareFilter(filter, interval, dateTimeType, context)
    }
    case 'greater_than_or_equal': {
      return dateCompareFilter(filter, interval, dateTimeType, context)
    }
    case 'less_than': {
      return dateCompareFilter(filter, interval, dateTimeType, context)
    }
    case 'less_than_or_equal': {
      return dateCompareFilter(filter, interval, dateTimeType, context)
    }
    // case ('between'):
    //   return dateCompareFilter(filter)
    default: {
      const x: never = type
      throw new NotImplementedError()
    }
  }
}
