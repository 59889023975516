/**
 * ここでfeature名を定義する。アプリ側で柔軟に設定できることが望ましいと考えているためDB側のenumにはしていない
 *
 * dev・csが管理画面で使うものでユーザー側が設定することはない。
 * ユーザーが設定するものは正規の組織設定の機能にすること。
 *
 * 今のところ命名規則の目安は
 * 実験的な新機能を使う場合はenable_*
 * 保守的に新機能を使わない場合はdisable_*
 *
 */
export const featureNames = [
  'enable_slack_comment',
  'enable_salesforce_user_permission',
  'enable_ri',
  'enable_manual_elt_model_update',
  'enable_nx_only_feature', // 厳密には機能というより個社向けの個別対応のためfeatureFlagで管理するか悩ましい
  'disable_version_check',
  'enable_waterfall_chart',
] as const satisfies ReadonlyArray<`${'enable' | 'disable'}_${string}`>

export type FeatureNames = (typeof featureNames)[number]
