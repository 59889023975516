import { type PgEmulator, range } from '@salescore/buff-common'
import type { CoreModel } from '@salescore/core'

import { companyModel, companyRecords } from './companyModel'

export const contactModel: CoreModel = {
  name: 'contacts',
  label: '担当者',
  properties: [
    {
      name: 'name',
      label: '名前',
      type: 'string',
      meta: 'name',
      write: {
        sourcePropertyName: `name`,
      },
      validations: [
        {
          function: {
            type: 'func',
            name: 'present',
            args: [], // TODO: 記法忘れた
          },
          message: ``,
          feedbackProperties: [],
        },
      ],
    },
    {
      name: '_url',
      label: 'レコードURL',
      type: 'string' as const,
      meta: 'record_url' as const,
    },
    {
      name: 'company_id',
      label: '会社ID',
      type: 'string',
      referenceTo: [
        {
          modelName: companyModel.name,
        },
      ],
      write: {
        sourcePropertyName: `company_id`,
      },
    },
    {
      name: 'created_at',
      label: '作成日時',
      type: 'datetime' as const,
      write: {
        sourcePropertyName: `created_at`,
      },
    },
  ],
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type contactRecord = {
  id: string
  name: string
  company_id: string | null
}
export const contactRecords: contactRecord[] = range(0, 9).map((index) => ({
  id: `contactId${index}`,
  name: `担当者${index}`,
  _url: `https://example.com/?id=${index}`,
  company_id: companyRecords[index % 3]!.id,
}))

export function initializecontactModelTable(database: PgEmulator) {
  database.initializeTable<contactRecord>({
    name: contactModel.name,
    records: contactRecords,
  })
}
