import { CORE_CONSTANT } from '../../../../constant'
import { SALESCORE_GOAL_MODEL } from '../../../../constant/models/SALESCORE_GOAL_MODEL'
import type { GoalConfig } from '../../../../schemas/misc/goal_config'
import type { ViewQueryMultiTablePivotQuery } from '../../../../schemas/query'
import type {
  ViewConfigField,
  ViewConfigFilter,
  ViewConfigPivot,
  ViewConfigSheet,
} from '../../../../schemas/view_config'
import type { CompileContext } from '../../compileViewConfig'
import { addDateFieldAndFilter } from '../kpi/addDateFieldAndFilter'
import { addSalescoreUserField } from '../kpi/addSalescoreUserFields'
import { addSalescoreUserGroupTreeAndFields } from '../kpi/addSalescoreUserGroupTreeAndFields'
import { addSalescoreUserTree } from '../kpi/addSalescoreUserTree'
import { updateConfigByAdditionalConfig } from '../kpi/updateConfigByAdditionalConfig'
import { compileSheetAndMeasure } from '../sheet/compileSheetAndMeasure'

export function compileGoalConfig(argument: {
  goalConfig: GoalConfig | undefined
  pivot: ViewConfigPivot | undefined
  context: CompileContext
  kpiIds: string[]
  userIds?: string[]
}): ViewQueryMultiTablePivotQuery[] {
  const { kpiIds, goalConfig } = argument
  if (goalConfig === undefined) {
    return []
  }
  const sumGoalTypeKpis = goalConfig.kpiViews
    .map((x) =>
      kpiIds.includes(x.id) && (x.aggregationFunction === 'sum' || x.aggregationFunction === undefined) ? x : undefined,
    )
    .compact()
  const avgGoalTypeKpis = goalConfig.kpiViews
    .map((x) => (kpiIds.includes(x.id) && x.aggregationFunction === 'avg' ? x : undefined))
    .compact()

  return [
    body({ ...argument, goalConfig, functionType: 'sum', kpiIds: sumGoalTypeKpis.map((x) => x.id) }),
    body({ ...argument, goalConfig, functionType: 'avg', kpiIds: avgGoalTypeKpis.map((x) => x.id) }),
  ].compact()
}

function body({
  goalConfig,
  pivot,
  context,
  kpiIds,
  userIds = [],
  functionType,
}: {
  goalConfig: GoalConfig
  pivot: ViewConfigPivot | undefined
  context: CompileContext
  kpiIds: string[]
  userIds?: string[]
  functionType: 'avg' | 'sum'
}): ViewQueryMultiTablePivotQuery | undefined {
  if (kpiIds.isBlank()) {
    return undefined
  }

  const goalViewConfig: ViewConfigSheet = {
    type: 'sheet',
    tree: {
      type: 'model',
      name: SALESCORE_GOAL_MODEL.name,
      modelName: SALESCORE_GOAL_MODEL.name,
    },
    fields: SALESCORE_GOAL_MODEL.properties.map(
      (property): ViewConfigField => ({
        type: 'property',
        property: {
          nodeName: SALESCORE_GOAL_MODEL.name,
          modelName: SALESCORE_GOAL_MODEL.name,
          propertyName: property.name,
        },
        measures:
          property.name === 'value'
            ? [
                {
                  type: 'preset',
                  name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
                  label: `目標`,
                  fieldName: [SALESCORE_GOAL_MODEL.name, property.name].join('_'),
                  function: functionType,
                },
              ]
            : [],
      }),
    ),
    filterTree: {
      logicalOperator: `and`,
      children: [],
      leafs: [
        {
          type: `property`,
          property: {
            nodeName: SALESCORE_GOAL_MODEL.name,
            modelName: SALESCORE_GOAL_MODEL.name,
            propertyName: `goal_config_id`,
          },
          filterType: `equal`,
          filterValue: goalConfig.id,
        },
        {
          type: `property`,
          property: {
            nodeName: SALESCORE_GOAL_MODEL.name,
            modelName: SALESCORE_GOAL_MODEL.name,
            propertyName: `kpi_id`,
          },
          filterType: `in`,
          filterValue: [...kpiIds],
        },
        userIds.length > 0
          ? {
              type: 'property',
              property: {
                nodeName: SALESCORE_GOAL_MODEL.name,
                modelName: SALESCORE_GOAL_MODEL.name,
                propertyName: 'user_id',
              },
              filterType: 'in',
              filterValue: [...userIds],
            }
          : undefined,
      ].compact() as ViewConfigFilter[],
    },
  }
  const configWithDateFilter = addDateFieldAndFilter(goalViewConfig, {
    property: {
      nodeName: SALESCORE_GOAL_MODEL.name,
      modelName: SALESCORE_GOAL_MODEL.name,
      propertyName: `date`,
    },
  })

  const sheetConfigWithUserTree =
    goalConfig.userType === 'user'
      ? addSalescoreUserTree(
          configWithDateFilter,
          {
            joinAs: 'id',
            property: {
              nodeName: SALESCORE_GOAL_MODEL.name,
              modelName: SALESCORE_GOAL_MODEL.name,
              propertyName: `user_id`,
            },
          },
          context,
        )
      : configWithDateFilter

  const sheetConfigWithUser =
    goalConfig.userType === 'user'
      ? addSalescoreUserField(
          sheetConfigWithUserTree,
          {
            joinAs: 'id',
            property: {
              nodeName: SALESCORE_GOAL_MODEL.name,
              modelName: SALESCORE_GOAL_MODEL.name,
              propertyName: `user_id`,
            },
          },
          context,
        )
      : goalConfig.userType === 'group'
        ? addSalescoreUserGroupTreeAndFields(
            sheetConfigWithUserTree,
            {
              nodeName: SALESCORE_GOAL_MODEL.name,
              modelName: SALESCORE_GOAL_MODEL.name,
              propertyName: `user_id`, // typeがgroupのとき、user_idにgroup_idが入っている
            },
            context,
          )
        : sheetConfigWithUserTree
  // adidtionalが必要になる文脈は、salescore_user_groups_d1などによる絞り込みが含まれるので、addUserした後にやる
  const goalViewConfigWithAdditionalConfig = updateConfigByAdditionalConfig(sheetConfigWithUser, context)
  const measures = (goalViewConfigWithAdditionalConfig.fields ?? []).flatMap((field) => field.measures ?? [])
  const results = measures
    .map((measure) =>
      compileSheetAndMeasure(
        measure,
        goalViewConfigWithAdditionalConfig,
        {
          rows: (pivot?.rows ?? []).filter((x) => x.key !== CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME),
          columns: (pivot?.columns ?? []).filter((x) => x.key !== CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME),
          dimensionsWithoutRollup: [
            // このレイヤーでkpi_idでグルーピングする(actualは実行時にkpi_idが付与される)
            {
              type: `property`,
              key: CORE_CONSTANT.KPI_PIVOT_KPI_ID_COLUMN_NAME,
              fieldType: `string`,
              label: `KPI_ID`,
              property: {
                nodeName: SALESCORE_GOAL_MODEL.name,
                modelName: SALESCORE_GOAL_MODEL.name,
                propertyName: `kpi_id`,
              },
            },
            // NOTE: 本来はここでKPIグループも付与する必要があるが、CASE WHENの処理が必要であり、SQLが煩雑になるため
            //       この処理はexecuteViewQueryForMultiTablePivotで行なっている
          ],
        },
        [
          {
            alias: CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key,
            property: {
              nodeName: SALESCORE_GOAL_MODEL.name,
              modelName: SALESCORE_GOAL_MODEL.name,
              propertyName: `date`,
            },
          },
        ],
        context,
      ),
    )
    .compact()
  const result = results.first()
  const schema = result?.query
  if (schema === undefined || schema.type !== 'aggregation') {
    return undefined
  }

  const query: ViewQueryMultiTablePivotQuery = {
    name: `goal`, // 結局つかってないはず
    kpiId: ``, // TODO: goalの場合は不要にしたい
    kpiName: ``, // TODO: 同上
    kpiGroupName: ``, // TODO: 同上
    role: `goal`,
    schema,
  }

  return query
}
