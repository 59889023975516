import { isSome, isTruthy } from '@salescore/buff-common'
import { t } from 'i18next'

import { SALESCORE_GOAL_MODEL } from '../../../../constant/models/SALESCORE_GOAL_MODEL'
import { USER_MODEL_ID_PROPERTY } from '../../../../constant/models/SALESCORE_USER_MODEL'
import { SALESCORE_USER_WITH_GROUP_MODEL } from '../../../../constant/models/SALESCORE_USER_WITH_GROUP_MODEL'
import type { ViewQueryMultiTablePivot } from '../../../../schemas/query'
import type { ViewUIKpiTimeSeries } from '../../../../schemas/ui/ui'
import type {
  SheetConfigOrReference,
  ViewConfigDimension,
  ViewConfigFilter,
  ViewConfigKpiTimeSeries,
  ViewConfigPivot,
} from '../../../../schemas/view_config'
import type { CompileContext, CompileViewConfigResponse } from '../../compileViewConfig'
import { compileKpiViewConfigWithoutGoal } from '../kpi/compileKpiViewConfig'
import { generateNodeFromDimension } from '../kpi/generateNodeFromDimension'
import { compileGoalConfig } from '../kpiPivot/compileGoalConfig'
import { generateDimensionFieldName } from '../sheet/measure/generateDimensionField'
import { generateAggregationDayFilterLeaf } from './generateAggregationDayFilterLeaf'
import { compileWaterfallViewConfig } from './waterfall/compileWaterfallViewConfig'

export function compileKpiTimeSeriesViewConfig(
  config: ViewConfigKpiTimeSeries,
  context: CompileContext,
): CompileViewConfigResponse {
  if (isTruthy(config.option?.asWaterfall)) {
    // 滝チャート
    return compileWaterfallViewConfig(config, context)
  }

  const kpiFragment = generateKpiFragment(config, context)
  const filterLeaf = generateAggregationDayFilterLeaf({
    config,
    modelSearcher: context.modelSearcher,
  })

  const { kpiPivot, goalPivot, queriedDimensions } = generatePivot(config, context)

  const compiledKpiConfig = compileKpiViewConfigWithoutGoal(kpiFragment, kpiPivot, {
    ...context,
    additionalConfig: {
      kpiParameter: {
        pivot: { rows: [], columns: [] },
        queryParameter: config.period,
      },
      filterLeafs: isSome(filterLeaf)
        ? [
            {
              type: 'drillDown',
              leaf: filterLeaf,
            },
          ]
        : [],
    },
  })

  const compiledKpiConfigQuery =
    compiledKpiConfig === undefined
      ? undefined
      : {
          kpiId: context.resources.view.id,
          kpiName: context.resources.view.name,
          kpiGroupName: context.resources.view.name,
          name: 'actual',
          role: 'actual',
          schema: compiledKpiConfig.query,
        }

  const [compiledGoalConfigQuery] = compileGoalConfig({
    goalConfig: config.goalConfigFragment,
    pivot: goalPivot,
    context,
    kpiIds: [config.kpiFragment?.id].compact(),
    userIds: config.filters?.userIds,
  })

  const query: ViewQueryMultiTablePivot = {
    type: 'multiTablePivot',
    tree: generateNodeFromDimension(kpiPivot, 0, []),
    queries: [compiledKpiConfigQuery, compiledGoalConfigQuery].compact(),
    pivot: {
      rows: kpiPivot.rows.map((v) => ({
        name: generateDimensionFieldName(v),
        sortedValues: v.sortedValues,
      })),
      columns: kpiPivot.columns.map((v) => ({
        name: generateDimensionFieldName(v),
        sortedValues: v.sortedValues,
      })),
    },
  }

  const ui: ViewUIKpiTimeSeries = {
    type: 'KpiTimeSeriesGraph',
    dimensions: compiledKpiConfig?.ui.dimensions ?? [],
    queriedDimensions,
  }

  return {
    ui: [ui],
    query,
    queries: [query],
    context,
  }
}

function generatePivot(config: ViewConfigKpiTimeSeries, context: CompileContext) {
  const dateX = config.kpiFragment?.date // KpiTimeSeries において、 date は dateX 相当である
  const dateSpan = (['day', 'week', 'month'] as const).find((v) => v === config.goalConfigFragment?.timeSpanType)

  const createdDateKpiPivotRow: ViewConfigDimension | undefined =
    dateX === undefined || dateSpan === undefined
      ? undefined
      : {
          type: 'property',
          key: `p_${dateX.property.modelName}_${dateX.property.propertyName}`,
          property: dateX.property,
          label: t(`横軸`),
          fieldType: 'datetime',
          dateSpan,
        }

  const breakdownPropertyKpiPivotRow: ViewConfigDimension | undefined = config.breakdownProperty

  const createdDateGoalPivotRow: ViewConfigDimension | undefined =
    createdDateKpiPivotRow === undefined
      ? undefined
      : {
          type: 'property',
          fieldType: createdDateKpiPivotRow.fieldType,
          key: createdDateKpiPivotRow.key,
          label: createdDateKpiPivotRow.label,
          property: {
            modelName: SALESCORE_GOAL_MODEL.name,
            nodeName: SALESCORE_GOAL_MODEL.name,
            propertyName: 'date',
          },
          dateSpan: createdDateKpiPivotRow.dateSpan,
        }

  const kpiPivot: ViewConfigPivot = {
    rows: [createdDateKpiPivotRow, breakdownPropertyKpiPivotRow].compact(),
    columns: [],
  }

  const goalPivot: ViewConfigPivot = {
    rows: [createdDateGoalPivotRow].compact(),
    columns: [],
  }

  const queriedDimensions = [
    createdDateKpiPivotRow === undefined ? undefined : ('createdDate' as const),
    breakdownPropertyKpiPivotRow === undefined ? undefined : ('breakdownProperty' as const),
  ].compact()

  return {
    kpiPivot,
    goalPivot,
    queriedDimensions,
  }
}

export function generateKpiFragment(
  config: ViewConfigKpiTimeSeries,
  context?: { additionalConfig?: { skipKpiTimeSeriesFilters?: boolean } },
) {
  if (config.kpiFragment === undefined) {
    return {
      type: 'kpi' as const,
    }
  }

  const kpiSheet = generateKpiSheet(config, context)

  const kpiFragment = { ...config.kpiFragment, sheet: kpiSheet }

  return kpiFragment
}

export function generateKpiSheet(
  config: ViewConfigKpiTimeSeries,
  context?: { additionalConfig?: { skipKpiTimeSeriesFilters?: boolean } },
): SheetConfigOrReference | undefined {
  if (context?.additionalConfig?.skipKpiTimeSeriesFilters === true) {
    return config.kpiFragment?.sheet
  }

  const kpiSheet = config.kpiFragment?.sheet?.type === 'sheet' ? config.kpiFragment.sheet : undefined

  if (kpiSheet === undefined) {
    return undefined
  }

  const dateYFilter: ViewConfigFilter[] =
    config.kpiFragment?.dateY !== undefined &&
    config.filters?.dateY?.startAt !== undefined &&
    config.filters.dateY.endAt !== undefined
      ? [
          {
            filterDateTimeType: 'date',
            filterType: 'greater_than_or_equal',
            filterValue: config.filters.dateY.startAt,
            property: config.kpiFragment.dateY.property,
            type: 'property',
          },
          {
            filterDateTimeType: 'date',
            filterType: 'less_than_or_equal',
            filterValue: config.filters.dateY.endAt,
            property: config.kpiFragment.dateY.property,
            type: 'property',
          },
        ]
      : []

  const userProperty = {
    nodeName: SALESCORE_USER_WITH_GROUP_MODEL.name,
    modelName: SALESCORE_USER_WITH_GROUP_MODEL.name,
    propertyName: USER_MODEL_ID_PROPERTY.name,
  }

  const userFilter: ViewConfigFilter | undefined =
    config.kpiFragment?.user !== undefined && config.filters?.userIds !== undefined && config.filters.userIds.length > 0
      ? {
          filterType: 'in',
          filterValue: config.filters.userIds,
          property: userProperty,
          type: 'property',
        }
      : undefined

  const breakdownFilter: ViewConfigFilter | undefined =
    config.breakdownProperty !== undefined &&
    config.breakdownProperty.type === 'property' &&
    config.filters?.breakdownProperties !== undefined &&
    config.filters.breakdownProperties.length > 0
      ? {
          filterType: 'in',
          filterValue: config.filters.breakdownProperties,
          property: config.breakdownProperty.property,
          type: 'property',
        }
      : undefined

  const filters = [...dateYFilter, userFilter, breakdownFilter].compact()

  if (filters.length === 0) {
    return kpiSheet
  }

  return {
    ...kpiSheet,
    filterTree: {
      children: [kpiSheet.filterTree].compact(),
      leafs: filters,
      logicalOperator: 'and',
    },
  }
}
