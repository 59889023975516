//
// 全てのアプリケーション起動時に必ず行われる初期化処理
//
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line no-console
  console.log(`initializing application...`) // Node.jsの初期化周りの処理に自信がないので、しばらくログを出しておく
}

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(isoWeek)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.extend(customParseFormat)
