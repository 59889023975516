import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import { convertSheetConfigToFormConfig } from '../../../functions/view/convertSheetConfigToFormConfig'
import type { CoreView } from '../../../schemas/View'
import { getCompanySheetView } from './companySheetView'
import { compileResourceWithoutViews } from './util'

export function getCompanyFormView(): CoreView {
  const config = convertSheetConfigToFormConfig(getCompanySheetView().config)!

  const result = compileViewConfig(config, compileResourceWithoutViews)

  return {
    id: 'companyFormView',
    name: 'companyFormView',
    config,
    queries: result!.queries,
    ui: result!.ui,
  }
}
