import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import { convertSheetConfigToFormConfig } from '../../../functions/view/convertSheetConfigToFormConfig'
import type { CoreView } from '../../../schemas/View'
import { getOpportunitySheetView } from './opporunitySheetView'
import { compileResourceWithoutViews } from './util'

export function getOpportunityFormView(): CoreView {
  const config = convertSheetConfigToFormConfig(getOpportunitySheetView().config)!

  const result = compileViewConfig(config, compileResourceWithoutViews)

  return {
    id: 'opportunityFormView',
    name: 'opportunityFormView',
    config,
    queries: result!.queries,
    ui: result!.ui,
  }
}
