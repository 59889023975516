import { isNull } from '@salescore/buff-common'

import { CORE_CONSTANT } from '../../../../constant'
import type { CoreModel } from '../../../../schemas/model/model'
import type {
  MonthlyAggregationDay,
  NodePropertyName,
  ViewConfigCustomFilter,
  ViewConfigKpiTimeSeries,
  WeeklyAggregationDay,
} from '../../../../schemas/view_config'
import type { ModelSearcher } from '../ModelSearcher'
import { generatePropertySql } from '../sheet/compileSheetViewConfigFields'

export function generateAggregationDayFilterLeaf({
  config,
  modelSearcher,
}: {
  config: ViewConfigKpiTimeSeries
  modelSearcher: ModelSearcher
}): ViewConfigCustomFilter | undefined {
  const aggregationDay = config.kpiFragment?.aggregationDay
  const snapshotSequenceModel = getRootSnapshotSequenceModel({ config, modelSearcher })

  if (isNull(aggregationDay) || isNull(snapshotSequenceModel)) {
    return
  }

  const snapshotSequenceNodeProperty: NodePropertyName = {
    modelName: snapshotSequenceModel.name,
    nodeName: snapshotSequenceModel.name,
    propertyName: 'created_at',
  }

  switch (aggregationDay.timeSpan) {
    case 'month': {
      return {
        type: 'custom',
        filterValue: generateMonthlyAggregationFilterSql({
          aggregationDay,
          nodeProperty: snapshotSequenceNodeProperty,
        }),
      }
    }
    case 'week': {
      return {
        type: 'custom',
        filterValue: generateWeeklyAggregationFilterSql({
          aggregationDay,
          nodeProperty: snapshotSequenceNodeProperty,
        }),
      }
    }
    default: {
      const x: never = aggregationDay
      throw new Error(`Unexpected timeSpan`)
    }
  }
}

function getRootSnapshotSequenceModel({
  config,
  modelSearcher,
}: {
  config: ViewConfigKpiTimeSeries
  modelSearcher: ModelSearcher
}): CoreModel | undefined {
  const sheet = config.kpiFragment?.sheet

  if (isNull(sheet) || sheet.type !== 'sheet') {
    return
  }

  const rootModelName = sheet.tree?.modelName
  return modelSearcher.searchModel(`${rootModelName}_${CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX}`)
}

function generateMonthlyAggregationFilterSql({
  aggregationDay,
  nodeProperty,
}: {
  aggregationDay: MonthlyAggregationDay
  nodeProperty: NodePropertyName
}): string {
  const { filterType, filterValue } = aggregationDay
  switch (filterType) {
    case 'day': {
      return generateMonthlyDayFilterSql({
        day: filterValue,
        nodeProperty,
      })
    }
    default: {
      const x: never = filterType
      throw new Error(`Unexpected filterType`)
    }
  }
}

function generateWeeklyAggregationFilterSql({
  aggregationDay,
  nodeProperty,
}: {
  aggregationDay: WeeklyAggregationDay
  nodeProperty: NodePropertyName
}): string {
  const { filterType, filterValue } = aggregationDay

  switch (filterType) {
    case 'dayOfWeek': {
      return generateDayOfWeekFilterSql({
        dayOfWeek: filterValue,
        nodeProperty,
      })
    }
    default: {
      const x: never = filterType
      throw new Error(`Unexpected filterType`)
    }
  }
}

function generateMonthlyDayFilterSql({ day, nodeProperty }: { day: string; nodeProperty: NodePropertyName }): string {
  return `TO_CHAR(${generatePropertySql(nodeProperty)}, 'DD') = '${day.padStart(2, '0')}'`
}

function generateDayOfWeekFilterSql({
  dayOfWeek,
  nodeProperty,
}: {
  dayOfWeek: WeeklyAggregationDay['filterValue']
  nodeProperty: NodePropertyName
}): string {
  const generateDayOfWeekFilterSql = (day: number): string => {
    return `TO_CHAR(${generatePropertySql(nodeProperty)}, 'D') = '${day}'`
  }

  switch (dayOfWeek) {
    case 'sunday': {
      return generateDayOfWeekFilterSql(1)
    }
    case 'monday': {
      return generateDayOfWeekFilterSql(2)
    }
    case 'tuesday': {
      return generateDayOfWeekFilterSql(3)
    }
    case 'wednesday': {
      return generateDayOfWeekFilterSql(4)
    }
    case 'thursday': {
      return generateDayOfWeekFilterSql(5)
    }
    case 'friday': {
      return generateDayOfWeekFilterSql(6)
    }
    case 'saturday': {
      return generateDayOfWeekFilterSql(7)
    }
    default: {
      const x: never = dayOfWeek
      throw new Error(`Unexpected filterValue`)
    }
  }
}
