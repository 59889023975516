import { compileViewConfig } from '../../../functions/view/compileViewConfig'
import type { CoreView } from '../../../schemas/View'
import type { ViewConfigField, ViewConfigSheet } from '../../../schemas/view_config'
import { companyModel } from '../models/companyModel'
import { contactModel } from '../models/contactModel'
import { opportunityModel } from '../models/opportunityModel'
import { compileResourceWithoutViews } from './util'

export function getOpportunitySheetView(): CoreView & { config: ViewConfigSheet } {
  const opportunityFields = opportunityModel.properties.map(
    (property): ViewConfigField => ({
      type: 'property',
      property: {
        nodeName: opportunityModel.name,
        modelName: opportunityModel.name,
        propertyName: property.name,
      },
      measures:
        property.name === 'name'
          ? [
              {
                type: 'preset',
                name: 'count',
                label: 'レコード数',
                function: 'count',
                fieldName: `${opportunityModel.name}_sales`,
              },
            ]
          : property.name === 'sales'
            ? [
                {
                  type: 'preset',
                  name: 'sum',
                  label: '合計',
                  function: 'sum',
                  fieldName: `${opportunityModel.name}_sales`,
                },
              ]
            : [],
    }),
  )

  const contactFields = contactModel.properties.map(
    (property): ViewConfigField => ({
      type: 'property',
      property: {
        nodeName: contactModel.name,
        // nodeName: `companies_created_by`,
        modelName: contactModel.name,
        propertyName: property.name,
      },
    }),
  )
  const companyFields = companyModel.properties.map(
    (property): ViewConfigField => ({
      type: 'property',
      property: {
        nodeName: companyModel.name,
        // nodeName: `companies_created_by`,
        modelName: companyModel.name,
        propertyName: property.name,
      },
    }),
  )

  const opportunitySheetViewConfig: ViewConfigSheet = {
    type: 'sheet',
    tree: {
      name: opportunityModel.name,
      modelName: opportunityModel.name,
      type: 'model',
      children: [
        {
          type: 'model',
          name: contactModel.name,
          modelName: contactModel.name,
          referenceToProperty: {
            nodeName: opportunityModel.name,
            modelName: opportunityModel.name,
            propertyName: `contact_id`,
            referenceTo: {
              modelName: contactModel.name,
            },
          },
          children: [
            {
              type: 'model',
              name: companyModel.name,
              modelName: companyModel.name,
              referenceToProperty: {
                nodeName: contactModel.name,
                modelName: contactModel.name,
                propertyName: `company_id`,
                referenceTo: {
                  modelName: companyModel.name,
                },
              },
            },
          ],
        },
      ],
    },
    fields: [...opportunityFields, ...contactFields, ...companyFields],
  }

  const result = compileViewConfig(opportunitySheetViewConfig, compileResourceWithoutViews)

  return {
    id: 'opportunitySheetView',
    name: 'opportunitySheetView',
    config: opportunitySheetViewConfig,
    queries: result!.queries,
    ui: result!.ui,
  }
}
