const compareFunctionForString = (a: string, b: string): 1 | 0 | -1 => {
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  return 0
}

export const compareFunction = (a: unknown, b: unknown): 1 | 0 | -1 => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b < 0 ? -1 : a === b ? 0 : 1
  }

  if (typeof a === typeof b) {
    // 型が同じの場合は、文字列としてソートする
    return compareFunctionForString(`${a}`, `${b}`) // eslint-disable-line @typescript-eslint/restrict-template-expressions
  }

  // 型が違う場合は、文字列としてソートする
  return compareFunctionForString(typeof a, typeof b)
}

export const multiCompareFunction = (as: unknown[], bs: unknown[]): 1 | 0 | -1 => {
  // eslint-disable-next-line unicorn/no-for-loop
  for (let index = 0; index < as.length; index++) {
    const a = as[index]
    const b = bs[index]
    const result = compareFunction(a, b)
    if (result !== 0) {
      return result
    }
  }

  return 0
}
