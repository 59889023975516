import 'antd/dist/reset.css'
import '../../assets/styles/antd.less'
import '../../assets/styles/tailwind.less'
import '../../assets/styles/external.less'
import '../../assets/styles/transition.less'
import '../../assets/styles/main.less'
import '../infra/bugsnag'

import { extendProperties } from '@salescore/buff-common'
import dayjs from 'dayjs'
import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react'

import { DefaultProvider } from '../presentation/common/DefaultProvider'
import { DefaultLayout } from '../presentation/components/layouts/DefaultLayout'
import { NoMemberLayout } from '../presentation/components/layouts/NoMemberLayout'

extendProperties()
dayjs.locale('ja')

const NO_MEMBER_LAYOUT_PATHS = new Set(['/sign_in', '/sign_out'])

// hydrationを無効化する
const SafeHydrate = ({ children }: { children: JSX.Element }) => {
  const [visibility, setVisibility] = useState(false)
  useEffect(() => {
    setVisibility(true)
  }, [])

  if (!visibility) {
    return <></>
  }

  return <>{typeof window === 'undefined' ? null : children}</>
}

const Page = (properties: AppProps) => {
  const { Component, router } = properties
  // const initialized = useAuthClientInitialized();

  // SPA only
  if (!process.browser) {
    return <></>
  }

  // if (!initialized) return <></>;

  // loggingはDefaultLayout側で行う

  if (NO_MEMBER_LAYOUT_PATHS.has(router.pathname)) {
    return (
      <DefaultProvider>
        <NoMemberLayout {...properties} />
      </DefaultProvider>
    )
  }

  return (
    <DefaultProvider>
      <DefaultLayout {...properties} />
    </DefaultProvider>
  )
}
export default Page
